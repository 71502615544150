import { addId, fetchBackendTime, fetchPastInputs } from "../commonHelpers";
import {
  WeeklyComponentDayOption,
  WeeklyFireStoreState,
} from "./SteppedComponentHelper";

import { ElevateWeeklyFirestoreState } from "../weeklyElevateInput/types";

/**
 * Convert FirestoreState from a schema without opportunityId to one that includes
 * an opportunityId. This has been done to account for resolving Companies that are part
 * of an Opportunity in SF per request from Emanuel and Diogo in 2022M12.
 */
function addIds<T extends ElevateWeeklyFirestoreState | WeeklyFireStoreState>(
  record: T
): T {
  return {
    ...record,
    sourcing: record.sourcing.map(addId),
    building: record.building.map(addId),
    admin: record.admin.map(addId),
  };
}

async function fetchInputWeek(user: any) {
  // get input Week
  const response = await fetchBackendTime(user);
  return response.weeklyInput;
}

async function fetchPastWeeklyInput(
  db: any,
  documentId: any
): Promise<WeeklyFireStoreState | null> {
  const collectionComposite = `weekly_input`;
  const result = (await fetchPastInputs(
    db,
    documentId,
    collectionComposite
  )) as WeeklyFireStoreState;
  if (result != null) return addIds(result);
  return result;
}

async function fetchPastWeeklyElevateInput(
  db: any,
  documentId: any
): Promise<ElevateWeeklyFirestoreState | null> {
  const collectionComposite = `weekly_elevate_input`;
  const result = (await fetchPastInputs(
    db,
    documentId,
    collectionComposite
  )) as ElevateWeeklyFirestoreState;
  if (result != null) return addIds(result);
  return result;
}

async function fetchPrevInputWeeks(user: any, numberOfPastWeeks: any) {
  const idToken = await user.getIdToken(true);
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/prevWeeksInfo?numberOfPastWeeks=${numberOfPastWeeks}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
  return response.json();
}

function filterAssetUnavailable<
  T extends ElevateWeeklyFirestoreState | WeeklyFireStoreState
>(
  previousWeekInput: T,
  sourceOptions: WeeklyComponentDayOption[],
  buildOptions: WeeklyComponentDayOption[],
  adminOptions: WeeklyComponentDayOption[]
): T {
  const filteredPreviousWeekInput = previousWeekInput;
  filteredPreviousWeekInput.sourcing = previousWeekInput.sourcing.filter(
    (asset) => sourceOptions.some((item) => item.id === asset.id)
  );
  filteredPreviousWeekInput.building = previousWeekInput.building.filter(
    (asset) => buildOptions.some((item) => item.id === asset.id)
  );
  filteredPreviousWeekInput.admin = previousWeekInput.admin.filter((asset) =>
    adminOptions.some((item) => item.id === asset.id)
  );
  return filteredPreviousWeekInput;
}

function resetOptionsDays<
  T extends ElevateWeeklyFirestoreState | WeeklyFireStoreState
>(selectedOptions: T): T {
  const optionsWithDays0 = selectedOptions;
  optionsWithDays0.sourcing = selectedOptions.sourcing.map((obj: any) => ({
    ...obj,
    days: 0,
  }));
  optionsWithDays0.building = selectedOptions.building.map((obj: any) => ({
    ...obj,
    days: 0,
  }));
  optionsWithDays0.admin = selectedOptions.admin.map((obj) => ({
    ...obj,
    days: 0,
  }));
  return optionsWithDays0;
}

async function fetchAdminOptions(): Promise<WeeklyComponentDayOption[]> {
  const options = [
    {
      assetName: "Workshop",
      fund: "Verdane",
      companyId: "0",
      opportunityId: null,
      days: 0,
      description: "",
    },
    {
      assetName: "Business Development",
      fund: "Verdane",
      companyId: "1",
      opportunityId: null,
      days: 0,
      description: "",
    },
    {
      assetName: "Investor Relations",
      fund: "Verdane",
      companyId: "2",
      opportunityId: null,
      days: 0,
      description: "",
    },
    {
      assetName: "Human Resources",
      fund: "Verdane",
      companyId: "3",
      opportunityId: null,
      days: 0,
      description: "",
    },
    {
      assetName: "Leaves",
      fund: "Verdane",
      companyId: "4",
      opportunityId: null,
      days: 0,
      description: "",
    },
    {
      assetName: "Other",
      fund: "Verdane",
      companyId: "5",
      opportunityId: null,
      days: 0,
      description: "",
    },
    {
      assetName: "Secondment",
      fund: "Verdane",
      companyId: "6",
      opportunityId: null,
      days: 0,
      description: "",
    },
  ].map(addId);
  return new Promise((resolve, reject) => resolve(options));
}

// TODO: fetch using commonHelpers.js/fetchCompanyCache
async function fetchAdminOptionsElevater(): Promise<
  WeeklyComponentDayOption[]
> {
  // Business Development is now replaced with categories with IDs from 7 to 13

  const options = [
    {
      assetName: "Workshop",
      fund: "Verdane",
      companyId: "0",
      opportunityId: null,
      days: 0,
      description: "Verdane workshops and Elevate internal workshops including any preparation.",
    },
    {
      assetName: "Investor Relations",
      fund: "Verdane",
      companyId: "2",
      opportunityId: null,
      days: 0,
      description: "Preparation and execution of investor meetings or investor content.",
    },
    {
      assetName: "Human Resources",
      fund: "Verdane",
      companyId: "3",
      opportunityId: null,
      days: 0,
      description: "For mentors and managers discussing things not attributable to other categories.",
    },
    {
      assetName: "Leaves",
      fund: "Verdane",
      companyId: "4",
      opportunityId: null,
      days: 0,
      description: "Vacation or other OOO such as sick leaves.",
    },
    {
      assetName: "Internal Meeting",
      fund: "Verdane",
      companyId: "7",
      opportunityId: null,
      days: 0,
      description: "Internal meetings not attributable to build or invest projects.",
    },
    {
      assetName: "Admin",
      fund: "Verdane",
      companyId: "8",
      opportunityId: null,
      days: 0,
      description: "Expenses, planning of leaves etc.",
    },
    {
      assetName: "Self-development",
      fund: "Verdane",
      companyId: "9",
      opportunityId: null,
      days: 0,
      description: "Education, conferences etc.",
    },
    {
      assetName: "External Presentations",
      fund: "Verdane",
      companyId: "11",
      opportunityId: null,
      days: 0,
      description: "Presentation on behalf of Verdane.",
    },
    {
      assetName: "Community",
      fund: "Verdane",
      companyId: "13",
      opportunityId: null,
      days: 0,
      description: "Related to specific Community projects such as CxO Days, Meet-ups, Webinars, Slack Community, introductions and 1-many activities.",
    },
    {
      assetName: "Product Development",
      fund: "Verdane",
      companyId: "14",
      opportunityId: null,
      days: 0,
      description: "This category is to record your time for completely new products in the pilot phase.",
    },
    {
      assetName: "Budgeting & Invoicing",
      fund: "Verdane",
      companyId: "15",
      opportunityId: null,
      days: 0,
      description: "Please use this category if you spend significant time (>30min) on this topic."
    },
    {
      assetName: "Partners and Suppliers",
      fund: "Verdane",
      companyId: "16",
      opportunityId: null,
      days: 0,
      description: "Sourcing of partners and suppliers, meetings with new or existing partners."
    },
    {
      assetName: "Data Collection",
      fund: "Verdane",
      companyId: "17",
      opportunityId: null,
      days: 0,
      description: "Collection and analysis of data not attributable to a specific company. E.g. surveys, including creating the survey."
    },
    {
      assetName: "Business Development",
      fund: "Verdane",
      companyId: "18",
      opportunityId: null,
      days: 0,
      description: "Development of strategy, OKRs, case studies etc."
    }
  ].map(addId);
  return new Promise((resolve, reject) => resolve(options));
}

export {
  resetOptionsDays,
  filterAssetUnavailable,
  fetchInputWeek,
  fetchPrevInputWeeks,
  fetchPastWeeklyInput,
  fetchAdminOptions,
  fetchPastWeeklyElevateInput,
  fetchAdminOptionsElevater,
};
