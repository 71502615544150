import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { MaintenanceInfo } from "routes";

export default function Maintenance() {
  const localUntil = new Date(MaintenanceInfo.until);
  return (
    <Card>
      <CardHeader>
        <h3 style={{ textAlign: "center" }}>
          &#10071;Ralloc is currently under maintenance &#128736;
        </h3>
      </CardHeader>
      <CardBody style={{ textAlign: "center" }}>
        {" "}
        We expect to return back at{" "}
        <b>
          {localUntil.toLocaleDateString()} {localUntil.toLocaleTimeString()}{" "}
        </b>
      </CardBody>
    </Card>
  );
}
