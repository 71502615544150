import {
  SteppedReducerAction,
  WeeklyElevateComponentOption,
} from "../steppedInput/SteppedComponentHelper";
import { Project, WeeklyElevateComponentState } from "./types";

function elevateInputListReducer(
  state: WeeklyElevateComponentState,
  action: SteppedReducerAction
): WeeklyElevateComponentState {
  switch (action.type) {
    case "SET_FETCHED_OPTIONS": {
      return { ...state, options: action.payload.fetchedOptions };
    }

    case "SET_FETCHED_SELECTION":
      return {
        ...state,
        selection: action.payload.fetchedSelection,
      };

    case "UPDATE_DAYS_SELECTION":
      return {
        ...state,
        selection: state.selection.map((company) => {
          if (company.projectId === action.payload.id) {
            return { ...company, days: action.payload.days };
          }
          return company;
        }),
        isUpdated: true,
      };

    case "CLEAR_ZERO_DAYS_SELECTION": {
      // Delete selections where days === 0
      const deletedEntries = state.selection.filter((c) => c.days === 0);
      return {
        ...state,
        options: [...state.options, ...deletedEntries],
        selection: state.selection.filter((c) => c.days > 0),
      };
    }

    case "SET_UPDATED":
      return {
        ...state,
        isUpdated: action.payload.isUpdated,
      };

    case "ADD_SELECTION": {
      // No options filtering here for editability.
      const payload: WeeklyElevateComponentOption = action.payload.newSelection;
      let updatedZeroDays = false;
      const newSelection = state.selection.map((entry) => {
        if (entry.projectId === payload.projectId) {
          // 0 days selection might still be there and user
          // might want to add the same entry, we replace in that case
          updatedZeroDays = true;
          return payload;
        }
        return entry;
      });
      if (!updatedZeroDays) {
        newSelection.push(payload);
      }
      return {
        ...state,
        selection: newSelection,
        isUpdated: true,
      };
    }

    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case "SET_ERROR":
      return {
        ...state,
        isError: action.payload.isError,
      };

    default:
      return state;
  }
}

export type ProjectListReducerAction = {
  type: "ADD_PROJECT" | "SET_FETCHED_OPTIONS";
  payload: any;
};

export function projectListReducer(
  state: Project[],
  action: ProjectListReducerAction
): Project[] {
  switch (action.type) {
    case "SET_FETCHED_OPTIONS": {
      return action.payload;
    }

    case "ADD_PROJECT": {
      const entry: Project = action.payload;
      const copy = [...state];
      const index = copy.findIndex((c) => c.id === action.payload);
      if (index === -1) {
        copy.push(entry);
      }
      return copy;
    }
    default:
      return state;
  }
}

export { elevateInputListReducer };
