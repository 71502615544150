import React from "react";
import PropTypes from "prop-types";
import { Alert } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  infoColor,
  successColor,
  warningColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const StyledAlert = withStyles({
  standardInfo: {
    background: infoColor[2],
    color: grayColor[2],
  },
  standardWarning: {
    background: warningColor[2],
    color: grayColor[2],
  },
  standardError: {
    background: warningColor[2],
    color: grayColor[2],
  },
  standardSuccess: {
    background: successColor[2],
    color: grayColor[2],
  },
  icon: {
    color: `${grayColor[2]} !important`,
  },
})(Alert);

export default function CustomAlert(props) {
  return (
    <StyledAlert
      color={props.color}
      variant={props.variant}
      severity={props.severity}
    >
      {props.children}
    </StyledAlert>
  );
}

CustomAlert.propTypes = {
  color: PropTypes.oneOf(["info", "warning", "error", "success", ""])
    .isRequired,
  severity: PropTypes.oneOf(["info", "warning", "error", "success", ""])
    .isRequired,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]).isRequired,
  children: PropTypes.any.isRequired,
};
