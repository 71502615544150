import React, { useReducer, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import useUnload from "context/use-unload.js";
import { useSnackbar } from "context/snackbar-context.js";
import style from "assets/jss/material-dashboard-pro-react/views/weeklyInput.js";
import {
  FormHelperText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import usePrompt from "components/Prompt/usePrompt";

// firestore
import app from "firebaseConfig";
import { getFirestore } from "firebase/firestore";

// custom functions

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

// core components

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { useAuth } from "context/auth-context.js";

import { InputActionType, useInputContext } from "context/input-context";
import DayWizard from "components/DayWizard/DayWizard";
import WeeklyInputStep2 from "./steps/WeeklyInputStep2";
import WeeklyInputStep1 from "./steps/WeeklyInputStep1";
import {
  fetchCompanyCache,
  InputFormPropType,
  InputFormDefaultProps,
  sendStateController,
  checkIfPastInputExists,
} from "../commonHelpers";
import { createBadge } from "../steppedInput/weeklyTablesHelpers";
import {
  fetchAdminOptionsElevater,
  fetchPrevInputWeeks,
  fetchPastWeeklyElevateInput,
} from "../steppedInput/weeklyHelpers";
import {
  inputListReducer,
  getTotalDays,
  setLoading,
  WeeklyComponentDayOption,
} from "../steppedInput/SteppedComponentHelper";
import { componentIds } from "../stackedInput/StackedInputHelper";
import { getProjectsById } from "./firestore";

import {
  ElevateWeeklyFirestoreState,
  Project,
  WeeklyElevateComponentState,
} from "./types";
import {
  convertHoursToDays,
  getTableRowsAdmin,
  getTableRowsBuildSource,
  setFetchedOptions,
} from "./helper";
import { elevateInputListReducer, projectListReducer } from "./reducer";
import WeeklyInputStep3 from "./steps/WeeklyInputStep3";
import { getInitWeek, WeekInfo } from "./WeeklyElevateInput";

const useStyles = makeStyles(style as any);

const getInitAction = (): WeeklyElevateComponentState => ({
  options: [],
  selection: [],
  deleted: [],
  isUpdated: false,
  isLoading: false,
  isError: false,
});

const containsNullOrUndefined = (...args: any[]) => {
  return args.some((x) => x == null);
};

type WeeklyMenuState = WeekInfo & {
  existsInFirestore: boolean;
};

function WeekElevateBackfill() {
  const db = getFirestore(app);
  const auth = useAuth();
  const note = useSnackbar();
  const inputContext = useInputContext();
  const classes = useStyles();

  const [pastWeeksInfo, setPastWeeksInfo] = useState<WeeklyMenuState[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<WeekInfo>(getInitWeek());

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [initialOptions, setInitialOptions] = useState({
    source: new Array<WeeklyComponentDayOption>(),
    build: new Array<WeeklyComponentDayOption>(),
    admin: new Array<WeeklyComponentDayOption>(),
  });

  // Initial state for sourcing, building, admin, workload
  const [sourceCompany, dispatchSourceCompany] = useReducer(
    elevateInputListReducer,
    getInitAction()
  );

  const [buildCompany, dispatchBuildCompany] = useReducer(
    elevateInputListReducer,
    getInitAction()
  );

  const [adminCompany, dispatchAdminCompany] = useReducer(
    inputListReducer,
    getInitAction()
  );

  const [activeProjectCache, dispatchActiveProjectCache] = useReducer(
    projectListReducer,
    []
  );

  const projectCacheMap = new Map(
    [...activeProjectCache].map((object) => {
      return [object.id, object];
    })
  );
  const adminRows = getTableRowsAdmin(
    adminCompany,
    projectCacheMap,
    dispatchAdminCompany
  );
  const buildRows = getTableRowsBuildSource(
    buildCompany,
    projectCacheMap,
    dispatchBuildCompany
  );
  const sourceRows = getTableRowsBuildSource(
    sourceCompany,
    projectCacheMap,
    dispatchSourceCompany
  );

  const getTotalRow = () => {
    const totalHours = getTotalDays([
      ...adminCompany.selection,
      ...buildCompany.selection,
      ...sourceCompany.selection,
    ]);

    if (totalHours > 0) {
      return [
        {
          total: true,
          colspan: "3",
          amount: createBadge("total-1", totalHours),
        },
      ];
    }
    return [];
  };

  // Workload tab state
  const [workload, setWorkload] = useState("");
  /**
   * clears previous input when the user selects a report that has not been filled
   */
  async function clearPastInput() {
    dispatchSourceCompany({
      type: "SET_FETCHED_SELECTION",
      payload: { fetchedSelection: [] },
    });
    dispatchBuildCompany({
      type: "SET_FETCHED_SELECTION",
      payload: { fetchedSelection: [] },
    });
    dispatchAdminCompany({
      type: "SET_FETCHED_SELECTION",
      payload: { fetchedSelection: [] },
    });
    setWorkload("");
  }
  async function setPastInput(
    firestoreState: ElevateWeeklyFirestoreState | null
  ) {
    if (firestoreState !== null && firestoreState !== undefined) {
      if (
        containsNullOrUndefined(
          firestoreState.sourcing,
          firestoreState.building,
          firestoreState.admin,
          firestoreState.workload
        )
      ) {
        note.sendNotification(
          "Your Firestore Cache is corrupt. Please contact an admin and report this incident.",
          "warning"
        );
      } else {
        note.sendNotification(
          `You already submitted a report for the selected week. To edit your report, re-submit it.`,
          "info"
        );
        dispatchSourceCompany({
          type: "SET_FETCHED_SELECTION",
          payload: { fetchedSelection: firestoreState.sourcing },
        });
        dispatchBuildCompany({
          type: "SET_FETCHED_SELECTION",
          payload: { fetchedSelection: firestoreState.building },
        });
        dispatchAdminCompany({
          type: "SET_FETCHED_SELECTION",
          payload: { fetchedSelection: firestoreState.admin },
        });
        setWorkload(firestoreState.workload);

        const pastProjectIds = [
          ...firestoreState.sourcing,
          ...firestoreState.building,
        ].map((e) => e.projectId);
        const pastProjects = await getProjectsById(db, pastProjectIds);
        dispatchActiveProjectCache({
          type: "SET_FETCHED_OPTIONS",
          payload: pastProjects,
        });
      }
    }
  }

  useUnload((e: any) => {
    if (
      sourceCompany.isUpdated ||
      buildCompany.isUpdated ||
      adminCompany.isUpdated
    ) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  useEffect(() => {
    // This function ensures the order in which resources are fetched.
    async function fetchChain() {
      const dispatchFunctions = [
        dispatchSourceCompany,
        dispatchAdminCompany,
        dispatchBuildCompany,
      ];
      setLoading(dispatchFunctions, true);

      const pastWeekInfos: WeekInfo[] = await fetchPrevInputWeeks(
        auth.user,
        18
      );

      const pastInputExists = await checkIfPastInputExists(
        db,
        auth.user.email,
        pastWeekInfos.map((p) => p.formattedWeek),
        "weekly_elevate_input"
      );

      setPastWeeksInfo(
        pastWeekInfos.map((input, index) => {
          return { ...input, existsInFirestore: pastInputExists[index] };
        })
      );

      const [sourceOptions, buildOptions, adminOptions] = await Promise.all([
        fetchCompanyCache(db, "elevate_sourcing_choices"),
        fetchCompanyCache(db, "elevate_building_choices"),
        fetchAdminOptionsElevater(),
      ]);

      setFetchedOptions(
        sourceOptions,
        buildOptions,
        adminOptions,
        dispatchSourceCompany,
        dispatchBuildCompany,
        dispatchAdminCompany
      );
      setInitialOptions({
        source: sourceOptions,
        build: buildOptions,
        admin: adminOptions,
      });
      setLoading(dispatchFunctions, false);
    }

    fetchChain();
  }, [auth.user, db]);

  const instruction = () => {
    return (
      <GridContainer justifyContent="center">
        <GridItem xs={10} lg={5}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="select-week">Week</InputLabel>
            <Select
              id="select-week"
              value={selectedWeek.formattedWeek}
              onChange={async (e) => {
                const weekFor = e.target.value as string;
                if (weekFor !== undefined) {
                  const dispatchFunctions = [
                    dispatchSourceCompany,
                    dispatchAdminCompany,
                    dispatchBuildCompany,
                  ];
                  const selection = pastWeeksInfo.find(
                    (info) => info.formattedWeek === weekFor
                  );
                  if (selection !== undefined) {
                    setSelectedWeek(selection);
                  }
                  setLoading(dispatchFunctions, true);
                  const pastInput = await fetchPastWeeklyElevateInput(
                    db,
                    `${auth.user.email}_${weekFor}`
                  );
                  if (pastInput !== null) {
                    setPastInput(convertHoursToDays(pastInput));
                  } else {
                    note.sendNotification(
                      `You have not filled in report for the selected week. Please fill it in and submit!`,
                      "info"
                    );
                    clearPastInput();
                  }
                  setFetchedOptions(
                    initialOptions.source,
                    initialOptions.build,
                    initialOptions.admin,
                    dispatchSourceCompany,
                    dispatchBuildCompany,
                    dispatchAdminCompany
                  );
                  setLoading(dispatchFunctions, false);
                }
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {pastWeeksInfo.map((weekInfo) => {
                return (
                  <MenuItem
                    value={weekInfo.formattedWeek}
                    key={weekInfo.formattedWeek}
                  >
                    {weekInfo.existsInFirestore ? (
                      <span>
                        {weekInfo.startWeek} - {weekInfo.endWeek} - week
                        {weekInfo.inputWeek}
                      </span>
                    ) : (
                      <span className={classes.forwardHighlight}>
                        {weekInfo.startWeek} - {weekInfo.endWeek} - week
                        {weekInfo.inputWeek}
                      </span>
                    )}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {/* infoMuiBackGround is a custom style to bring back alert */}
              <div className={classes.infoMuiBackGround}>
                <Alert severity="info">
                  missing report is in{" "}
                  <span className={classes.forwardHighlight}>Pink</span>
                </Alert>
              </div>
            </FormHelperText>
          </FormControl>
        </GridItem>
      </GridContainer>
    );
  };

  const tableRows = [
    ...sourceRows,
    ...buildRows,
    ...adminRows,
    ...getTotalRow(),
  ];

  // This action is safe to be called on every submit,
  // A successfully submitted form is always considered complete in the current
  // logic. Reducer makes sure incompletecount >= 0
  const callbackOnSuccess = () => {
    setPastWeeksInfo(
      pastWeeksInfo.map((info) => {
        if (info.formattedWeek === selectedWeek.formattedWeek) {
          return {
            ...info,
            existsInFirestore: true,
          };
        }
        return info;
      })
    );
    const analytics = getAnalytics(app);
    logEvent(analytics, "Elevate Weekly Report Submitted");
    inputContext.dispatch({
      type: InputActionType.DECREMENT_INCOMPLETE_COUNT,
      payload: componentIds.WEEKLY,
    });
  };

  usePrompt(
    "You are about to leave the Weekly Report without having saved your inputs. Unsaved changes will be discarded. Do you want to continue?",
    sourceCompany.isUpdated || buildCompany.isUpdated || adminCompany.isUpdated
  );

  const checkIfEntryInTableExists = (project: Project) => {
    const match = [...buildCompany.selection, ...sourceCompany.selection].find(
      (company) => company.projectId === project.id
    );
    if (match !== undefined) {
      return match.days > 0;
    }
    return false;
  };

  const weekFor = `${selectedWeek.formattedWeek}`;

  const successCallback = () => {
    note.sendNotification(
      `Successfully submitted report for ${selectedWeek.startWeek} - ${selectedWeek.endWeek}. To edit your report, re-submit it.`,
      "info"
    );
  };
  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={10}>
        <DayWizard
          isSubmitLoading={isSubmitLoading}
          isPast
          pastReportText="You are backfilling past reports."
          initialStep={2}
          steps={[
            {
              stepName: "Source & Invest",
              component: (
                <WeeklyInputStep1
                  dispatchActiveProjectCache={dispatchActiveProjectCache}
                  checkIfEntryInTableExists={checkIfEntryInTableExists}
                  tableRows={tableRows}
                  sourceCompany={sourceCompany}
                  dispatchSourceCompany={dispatchSourceCompany}
                />
              ),
            },

            {
              stepName: "Build & Exit",
              component: (
                <WeeklyInputStep2
                  buildCompany={buildCompany}
                  dispatchBuildCompany={dispatchBuildCompany}
                  dispatchActiveProjectCache={dispatchActiveProjectCache}
                  checkIfEntryInTableExists={checkIfEntryInTableExists}
                  tableRows={tableRows}
                />
              ),
            },

            {
              stepName: "Other",
              component: (
                <WeeklyInputStep3
                  adminCompany={adminCompany}
                  dispatchAdminCompany={dispatchAdminCompany}
                  workload={workload}
                  tableRows={tableRows}
                  setWorkload={setWorkload}
                />
              ),
            },
          ]}
          title="Elevate Weekly Report"
          finishButtonClick={() => {
            if (selectedWeek.formattedWeek === "") {
              // week is not selected
              note.sendNotification(
                "Please select the week from the drop down first before making an input! Thank you!",
                "error"
              );
              return;
            }
            if (workload === "") {
              note.sendNotification(
                "You did not select your expected workload, which is a required input. Please select select your epxected workload and re-submit the report.",
                "error"
              );
              return;
            }
            const totalDays = getTotalDays([
              ...adminCompany.selection,
              ...buildCompany.selection,
              ...sourceCompany.selection,
            ]);
            if (totalDays === 0) {
              note.sendNotification(
                "The report you try to submit is empty. Please add items to your report first, and then submit it again.",
                "error"
              );
              return;
            }
            // This deletes 0 day inputs from firestore
            const firestoreState = {
              admin: adminCompany.selection.filter((c) => c.days !== 0),
              building: buildCompany.selection.filter((c) => c.days !== 0),
              sourcing: sourceCompany.selection.filter((c) => c.days !== 0),
              workload,
              isApproved: true,
            };
            sendStateController(
              "week_for",
              weekFor,
              auth.user,
              db,
              "weekly_elevate_input",
              firestoreState,
              setIsSubmitLoading,
              note,
              successCallback,
              [
                dispatchAdminCompany,
                dispatchBuildCompany,
                dispatchSourceCompany,
              ],
              callbackOnSuccess,
              `${auth.user.email}_${weekFor}`
            );
          }}
          timeInstruction={instruction()}
        />
      </GridItem>
    </GridContainer>
  );
}

WeekElevateBackfill.prototype = InputFormPropType;
WeekElevateBackfill.defaultProps = InputFormDefaultProps;
export default WeekElevateBackfill;
