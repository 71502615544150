/**
 * Converts a string formatted date to Integer
 * @param date date in format YYYY-MM-DD
 * @returns Integer in format YYYYMMDD if provided string is valid, else null
 */
export function formatDateYYYY_MM_DD(date: number) {
  const asString = date.toString();
  if (asString.length != 8) {
    console.warn(`invalid input:${date}`);
    return null;
  }
  const dd = asString.substring(6, 8);
  const mm = asString.substring(4, 6);
  const yyyy = asString.substring(0, 4);
  return `${yyyy}-${mm}-${dd}`;
}
/**
 * Converts an integer formatted date to String
 * @param date date in integer format YYYYMMDD
 * @returns String in format YYYY-MM-DD if provided integer is valid, else null
 */
export function formatYYYY_MM_DDAsInteger(date: string | null) {
  if (date == null || date.length != 10) {
    console.warn(`invalid input:${date}`);
    return null;
  }
  const parts = date.split("-");
  if (parts.length === 3) {
    return parseInt(parts[0] + parts[1] + parts[2]);
  } else {
    console.warn(`invalid input:${date}`);
    return null;
  }
}

/**
 * Gets string representation of integer date provided in format YYYYMMDD
 * The returned string is in format yyyy-mm-dd
 */
export function formatDateAsString(date: number) {
  if (date == null) {
    return null;
  }
  let asString = date.toString();
  if (asString.length != 8) {
    console.error(`Provided date is invalid:${date}`);
    return null;
  }
  const dd = parseInt(asString.substring(6, 8));
  const mm = parseInt(asString.substring(4, 6)) - 1;
  const yyyy = parseInt(asString.substring(0, 4));
  const jsDate = new Date(yyyy, mm, dd, 0, 0, 0, 0);
  return jsDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}
/**
 * Converts to string and prepends the provided integer with 0s
 * in case the integer is shorter than the target length.
 * For example: for month pad(2,1) is converted to "01"
 * @param len target length of the string
 * @param num integer
 * @returns
 */
export function pad(len: number, num: number) {
  const numStr = num.toString();
  if (numStr.length < len) {
    return "0".repeat(len - numStr.length) + numStr;
  }
  return numStr;
}
/**
 * Prepends the provided string with 0s
 * in case the string is shorter than the target length.
 * For example: for month pad(2,"1") is converted to "01"
 * @param len target length of the string
 * @param str string
 * @returns
 */
export function padStr(len: number, str: string) {
  if (str.length < len) {
    return "0".repeat(len - str.length) + str;
  }
  return str;
}
