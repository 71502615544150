import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "components/CustomButtons/Button";
import { Link } from "react-router-dom";

export default function WeeklyInputDialog(props) {
  const { setOpenDialog, isOpenDialog, type, message } = props;

  return (
    <Dialog
      open={isOpenDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="weekly-report-dialogue"
      maxWidth="md"
    >
      <DialogTitle id="input-company">
        {type === "success" ? "Success 🎉" : "Error"}
      </DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Link to="/inbox">
          <Button color="primary">Open Inbox</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}

WeeklyInputDialog.propTypes = {
  isOpenDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  type: PropTypes.string,
  message: PropTypes.string,
};
