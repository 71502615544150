import React from "react";
// @material-ui/core components
import { withStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import DayInput from "components/DayInput/DayInput.js";
import {
  ComponentState,
  GenericComponentDayOption,
  SteppedReducerAction,
} from "../SteppedComponentHelper";

type Props = {
  sourceCompany: ComponentState<GenericComponentDayOption>;
  dispatchSourceCompany: React.Dispatch<SteppedReducerAction>;
  tableRows: Array<any>;
};

function WeeklyInputStep1({
  sourceCompany,
  dispatchSourceCompany,
  tableRows,
}: Props) {
  return (
    <form>
      <GridContainer justifyContent="center" spacing={4}>
        <DayInput
          tableRows={tableRows}
          tableHead={["Asset Name", "Actions", "Days"]}
          labelText="Process Name"
          labelUnits="Days"
          inputSteps={0.25}
          companyList={sourceCompany}
          dispatchCompanyList={dispatchSourceCompany}
          emptyMessage="Your report is empty."
          instruction="Note: Make sure to select Pre-DEM to book opportunities that have not yet passed DEM."
          secondInstruction="Follow-on processes should be logged as Source & Invest."
        />
      </GridContainer>
    </form>
  );
}

export default withStyles({})(WeeklyInputStep1);
