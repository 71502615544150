import React, { ReactNode, useState } from "react";
// nodejs library to set properties for components
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@mui/styles"; // this has to be changed
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

 
 
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles as any);

interface InputProps {
  loading: boolean;
  value: any;
  options: any;
  filterOptions?: any;
}

interface Props {
  labelText: ReactNode;
  labelProps: object;
  id: string;
  inputProps: InputProps;
  numberProps: object;
  formControlProps: object;
  inputRootCustomClasses: string;
  error: boolean;
  success: boolean;
  white: boolean;
  helperText: ReactNode;
  enterCallback: () => void;
}

export default function CustomAutocomplete(props: any) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    enterCallback,
  } = props;

  // sort options to get ordered fund categories
  inputProps.options = groupSortFlatten(
    inputProps.options,
    "fund",
    "assetName"
  );

  if (inputProps.filterOptions == null) {
    // filter options to make names separated by "-" more easily searchable
    inputProps.filterOptions = createFilterOptions({
      stringify: (option: any) =>
        option.assetName + option.assetName.replace(/-/g, " "),
    });
  }

  const [shrinkLabel, setShrinkLabel] = useState(false);
  const labelClasses = classNames({
    [` ${classes.labelRootError}`]: error,
    [` ${classes.labelRootSuccess}`]: success && !error,
  });

  function groupSortFlatten(
    array: Array<any>,
    groupField: string,
    sortField: string
  ) {
    const groups: any = {}; // This object will end being keyed by groups, and elements will be arrays of the rows within the given array, which have been sorted by the sortField
    // Put all the rows into groups
    for (let i = 0; i < array.length; i++) {
      const row = array[i];
      const groupValue: any = row[groupField];
      groups[groupValue] = groups[groupValue] || [];
      groups[groupValue].push(row);
    }
    // Sort each group
    for (const groupValue in groups) {
      groups[groupValue] = groups[groupValue].sort(
        (a: any, b: any) => -b[sortField].localeCompare(a[sortField])
      );
    }
    // Flatten the dictionary
    let sortedGroupsFlat: any = [];
    for (const [key, value] of Object.entries(groups)) {
      sortedGroupsFlat = [...sortedGroupsFlat, ...(value as any)];
    }
    return sortedGroupsFlat;
  }

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });

  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });

  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });

  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }

  const helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });

  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      variant="standard"
    >
      {labelText !== undefined ? (
        <InputLabel
          shrink={shrinkLabel}
          className={`${classes.labelRoot} ${labelClasses}`}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Autocomplete
        noOptionsText="No results"
        id={id}
        onSelect={() => setShrinkLabel(true)}
        {...inputProps}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={(e: any) => {
              if (e.code === "Enter" && e.target.value) {
                if (enterCallback != null) {
                  enterCallback(e.target.value);
                }
              }
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                input: inputClasses,
                root: marginTop,
                disabled: classes.disabled,
                underline: underlineClasses,
              },
            }}
            variant="standard"
          />
        )}
      />
      {helperText !== undefined ? (
        <FormHelperText id={`${id}-text`} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
