import React from "react";
import { makeStyles } from "@mui/styles";

// material-ui icons
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";

import Button from "components/CustomButtons/Button";
import Badge from "components/Badge/Badge";

import NumberFormatter from "constants/formatter.js";
import style from "assets/jss/material-dashboard-pro-react/components/dayInput.js";
import { SteppedReducerAction } from "./SteppedComponentHelper";

const useStyles = makeStyles(style as any);

type DispatchFunType = (action: SteppedReducerAction) => void;

type DeleteButtonProps = {
  id: string;
  dispatchFun: DispatchFunType;
};
type UpdateButtonProps = DeleteButtonProps & {
  days: number;
  stepSize: number;
};

const updateAssetDays = (
  id: string,
  days: number,
  updateFun: (input: number) => number,
  dispatchFun: DispatchFunType
) => {
  const updateDays = updateFun(days);
  dispatchFun({
    type: "UPDATE_DAYS_SELECTION",
    payload: { id, days: updateDays },
  });
};

function IncrementButton({
  id,
  dispatchFun,
  days,
  stepSize,
}: UpdateButtonProps) {
  const classes = useStyles();
  const updateFunForward = (x: number) => {
    return x + stepSize;
  };

  return (
    <Button
      color="primary"
      size="sm"
      className={classes.actionButton}
      onClick={() => {
        updateAssetDays(id, days, updateFunForward, dispatchFun);
      }}
    >
      <Add className={classes.icon} />
    </Button>
  );
}
// the button in the table next to the asset. Can be used to delete the Input.
function DeleteButton({ id, dispatchFun }: DeleteButtonProps) {
  const classes = useStyles();
  const updateFun = () => {
    return 0;
  };
  return (
    <Button
      color="danger"
      className={classes.actionButton}
      onClick={() => updateAssetDays(id, 0, updateFun, dispatchFun)}
    >
      <Delete className={classes.icon} />
    </Button>
  );
}

function DecrementButton({
  id,
  stepSize,
  dispatchFun,
  days,
}: UpdateButtonProps) {
  const classes = useStyles();
  const updateFunForward = (x: number) => {
    if (x > 0) {
      return x - stepSize;
    }
    return x;
  };

  return (
    <Button
      color="primary"
      size="sm"
      className={classes.actionButton}
      onClick={() => {
        updateAssetDays(id, days, updateFunForward, dispatchFun);
      }}
    >
      <RemoveIcon className={classes.icon} />
    </Button>
  );
}

const daysControl = (
  id: string,
  days: number,
  dispatchFun: DispatchFunType,
  stepSize: number
) => {
  return (
    <span>
      <IncrementButton
        id={id}
        days={days}
        dispatchFun={dispatchFun}
        stepSize={stepSize}
      />
      <DecrementButton
        id={id}
        days={days}
        dispatchFun={dispatchFun}
        stepSize={stepSize}
      />
      <DeleteButton id={id} dispatchFun={dispatchFun} />
    </span>
  );
};

const getRowColor = (days: number) => {
  if (days === 0) {
    return "danger";
  }
  return "";
};

const createBadge = (index: string | number, days: number) => {
  return (
    <Badge key={index} color="rose">
      {NumberFormatter.format(days)}
    </Badge>
  );
};

export { getRowColor, daysControl, createBadge };
