import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

// core components
import { Container } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import useMediaQuery from "@mui/material/useMediaQuery";

// custom components
import CustomInput from "components/CustomInput/CustomInput";
import CustomAutocomplete from "components/CustomAutocomplete/CustomAutocomplete";
import { Spinner } from "components/Spinner/Spinner";
import DayTable from "components/DayTable/DayTable.js";

import selectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import style from "assets/jss/material-dashboard-pro-react/components/dayInput.js";

const useStyles = makeStyles({ ...style, ...selectStyle });

export default function DayInput(props) {
  const {
    companyList,
    dispatchCompanyList,
    emptyMessage,
    instruction,
    secondInstruction,
    labelText,
    labelUnits,
    inputSteps,
    tableRows,
    tableHead,
  } = props;
  const [asset, setAsset] = useState(null);
  const [assetValid, setAssetValid] = useState("");
  const [assetDays, setAssetDays] = useState(0);
  const [assetDaysValid, setAssetDaysValid] = useState("");
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:801px)");

  const optionsList = (options) => {
    // sort the select list alphabetically
    // this works well with Autocomplete's sorting. Depending on which
    // component is loaded, the options are sorted accordingly.
    options.sort((a, b) => {
      if (a.assetName > b.assetName) {
        return 1;
      }
      if (a.assetName < b.assetName) {
        return -1;
      }
      return 0;
    });

    return options.map((item, key) => (
      <MenuItem
        key={`item-${key}`}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value={item}
      >
        {item.assetName}
      </MenuItem>
    ));
  };
  const verifyAsset = (asset) => {
    if (
      asset === null ||
      asset === "" ||
      typeof asset.assetName === "undefined"
    ) {
      return false;
    }
    if (asset.assetName.length > 0) {
      return true;
    }
    return false;
  };

  const verifyQuarter = (value, inputSteps) => {
    // second terms catches (empty) strings
    if (
      value % inputSteps === 0 &&
      !Number.isNaN(Number.parseInt(value)) &&
      value !== 0 &&
      value > 0
    ) {
      return true;
    }
    return false;
  };

  // Add a selected asset with the respective Days Input to the selection.
  const addAsset = () => {
    if (assetValid === "success") {
      if (assetDaysValid === "success") {
        const dayInput = assetDays;
        const newItem = { ...asset, days: dayInput };

        dispatchCompanyList({
          type: "ADD_SELECTION",
          payload: { newSelection: newItem },
        });

        // remove added asset from autocomplete option
        setAsset(null); // clear autocomplete selection
        setAssetValid("error");
        setAssetDays(0); // clear days input
        setAssetDaysValid("error");
      } else {
        setAssetDaysValid("error");
      }
    } else {
      setAssetValid("error");
    }
  };

  // controller that checks validation state before updating inputs.
  const change = (stateName, newValue, validationSuccessful) => {
    if (stateName === "asset") {
      setAsset(newValue);
      if (validationSuccessful === true) {
        setAssetValid("success");
      } else if (validationSuccessful === false) {
        setAssetValid("error");
      } else {
        throw new Error("Invalid validation result.");
      }
    } else if (stateName === "assetDays") {
      setAssetDays(Number.parseFloat(newValue));
      if (validationSuccessful === true) {
        setAssetDaysValid("success");
      } else if (validationSuccessful === false) {
        setAssetDaysValid("error");
      } else {
        throw new Error("Invalid validation result.");
      }
    } else {
      throw new Error("Invalid state to be updated");
    }
  };

  const compositeLabelText = <span>{labelText}</span>;
  const compositeLabelUnits = <span>{labelUnits}</span>;

  return (
    <GridContainer justifyContent="center" spacing={4}>
      <GridItem className={classes.dayGridItem} xs={10} sm={7}>
        {matches ? (
          <CustomAutocomplete
            labelText={compositeLabelText}
            success={assetValid === "success"}
            error={assetValid === "error"}
            inputProps={{
              loading: companyList.isLoading,
              value: asset,
              onChange: (e, newValue) => {
                change("asset", newValue, verifyAsset(newValue));
              },
              inputValue: autocompleteInputValue,
              onInputChange: (e, newInputValue) => {
                setAutocompleteInputValue(newInputValue);
              },
              renderOption: (param1: any, option: any) => {
                // key must be the last param as it must override key in param1
                if (Object.hasOwn(option, "description")) {
                  if (option.description !== "") {
                    const title = (
                      <div
                        style={{ whiteSpace: "pre-line" }}
                        {...param1}
                        key={option.id}
                      >
                        {option.description}
                      </div>
                    );
                    return (
                      <Tooltip title={title} {...param1} key={option.id}>
                        <Container maxWidth={false}>
                          {option.assetName}
                        </Container>
                      </Tooltip>
                    );
                  }
                }
                return (
                  <Container {...param1} key={option.id}>
                    {option.assetName}
                  </Container>
                );
              },
              options: companyList.options,
              groupBy: (option) => option.fund,
              getOptionLabel: (option) =>
                option.assetName ? option.assetName : "",
            }}
            formControlProps={{
              fullWidth: true,
            }}
            id="assetName"
          />
        ) : (
          <FormControl
            fullWidth
            className={classes.selectFormControl}
            data-testid="weekly-select"
            variant="standard"
          >
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              {`Select ${labelText}`}
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.selectDay,
              }}
              value={autocompleteInputValue}
              onChange={(e) => {
                setAutocompleteInputValue(e.target.value);
                change("asset", e.target.value, verifyAsset(e.target.value));
              }}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select",
              }}
            >
              {optionsList(companyList.options)}
            </Select>
          </FormControl>
        )}
      </GridItem>

      <GridItem xs={3} sm={2}>
        <CustomInput
          success={assetDaysValid === "success"}
          error={assetDaysValid === "error"}
          labelText={compositeLabelUnits}
          id="assetDays"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: "number",
            value: assetDays,
            onChange: (event) => {
              change(
                "assetDays",
                event.target.value,
                verifyQuarter(event.target.value, inputSteps)
              );
            },
          }}
          numberProps={{
            step: inputSteps,
            min: 0,
          }}
        />
      </GridItem>

      <GridItem className={classes.addGridItem} xs={2} sm={1}>
        <Tooltip title="Add Input" aria-label="add-input">
          <Button
            data-testid="weekly-add"
            color="primary"
            size="sm"
            className={classes.addAssetButton}
            onClick={() => addAsset()}
          >
            Add
          </Button>
        </Tooltip>
      </GridItem>

      {companyList.isLoading ? (
        <GridItem xs={10} sm={10}>
          <Spinner
            size="medium"
            position="component"
            label="Loading past inputs"
          />
        </GridItem>
      ) : (
        <GridItem xs={12} sm={10}>
          {tableRows.length > 0 ? (
            <DayTable tableRows={tableRows} tableHead={tableHead} />
          ) : (
            <h5>
              {emptyMessage}
              <br />
            </h5>
          )}
          <h5>
            <small>{instruction}</small>
            {secondInstruction ? (
              <>
                <br />
                <small>{secondInstruction}</small>
              </>
            ) : (
              ""
            )}
          </h5>
        </GridItem>
      )}
      {props.children}
    </GridContainer>
  );
}

DayInput.propTypes = {
  companyList: PropTypes.object,
  dispatchCompanyList: PropTypes.func,
  emptyMessage: PropTypes.string,
  instruction: PropTypes.string,
  secondInstruction: PropTypes.string,
  labelText: PropTypes.string,
  labelUnits: PropTypes.string,
  inputSteps: PropTypes.number,
  tableRows: PropTypes.array,
  tableHead: PropTypes.array.isRequired,
  children: PropTypes.any,
};
