import { makeStyles } from "@mui/styles";
import React from "react";

import style from "assets/jss/material-dashboard-pro-react/components/dayInput.js";
import Delete from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "components/CustomButtons/Button";
import Table from "components/Table/Table.js";
import { InputType, metricInfo, MetricValue } from "./BenchmarkInput";
import { formatDateAsString } from "./dateHelper";

const useStyles = makeStyles(style as any);

export default function AddedResultTable(props: {
  header: Map<string, string>;
  data: MetricValue[];
  callbackOnDelete: (data: MetricValue) => void;
  callbackOnUpdate: (data: MetricValue) => void;
}) {
  const classes = useStyles();
  const { data, callbackOnDelete, callbackOnUpdate, header } = props;
  const fieldsToIgnore = [
    "state",
    "updatedAt",
    "companyId",
    "metricName",
    "last_edit_user_id",
  ];
  const headerValuesSet: Array<string> = ["date"];
  const rightAlignFieldIndex: Array<number> =
    []; /** metric or numeric fields are right aligned */
  data.forEach((d) =>
    Object.keys(d).forEach((k) => {
      if (!fieldsToIgnore.includes(k) && !headerValuesSet.includes(k)) {
        headerValuesSet.push(k);
        rightAlignFieldIndex.push(headerValuesSet.length - 1);
      }
    })
  );
  const rightAlignFieldClasses = [...Array(rightAlignFieldIndex.length)].map(
    (_) => classes.right
  );
  const rows = data.map((d: MetricValue, i) => {
    const cells = headerValuesSet.map((h) => {
      const value = (d as any)[h];
      if (h === "date") {
        return formatDateAsString(value);
      }
      const info = metricInfo.get(d.metricName)?.get(h);
      if (info != null) {
        if (!info.isMandatory && (value == null || value === "")) {
          return "---";
        }
        if (info.inputType === InputType.CUSTOM_PERCENTAGE) {
          return `${value} %`;
        }
      }
      return value;
    });
    cells.push(
      <>
        <Button
          className={classes.actionButton}
          onClick={() => callbackOnUpdate(d)}
          color="primary"
        >
          <EditIcon className={classes.icon} />
        </Button>

        <Button
          className={classes.actionButton}
          onClick={() => callbackOnDelete(d)}
          color="danger"
        >
          <Delete className={classes.icon} />
        </Button>
      </>
    );
    let color = "";
    if (d.state === "NEW" || d.state === "UPDATED") {
      color = "success";
    }
    return { data: cells, color };
  });
  headerValuesSet.push("Actions");
  return (
    <Table
      striped
      hover
      tableHead={headerValuesSet.map((h) => {
        const value = header.get(h);
        if (value) {
          return value;
        }
        return h;
      })}
      tableData={rows}
      customCellClasses={rightAlignFieldClasses}
      customClassesForCells={rightAlignFieldIndex}
      customHeadCellClasses={rightAlignFieldClasses}
      customHeadClassesForCells={rightAlignFieldIndex}
    />
  );
}
