import {
  doc,
  setDoc,
  getDocs,
  deleteDoc,
  collection,
  serverTimestamp,
  where,
  query,
} from "firebase/firestore";
import { MetricValue } from "./BenchmarkInput";
import { pad } from "./dateHelper";

const collectionName = `${process.env.REACT_APP_FIREBASE_DB_PREFIX}benchmark_input_v2`;

function formatId(data: MetricValue) {
  return `${data.companyId}_${data.metricName}_${data.date.toString()}`;
}

async function writeToFirestore(
  // db: firestore.firestore.Firestore, TODO find a type
  db: any,
  data: MetricValue
) {
  const copy = {
    ...data,
    updatedAt: serverTimestamp(),
  };
  const docRef = doc(db, collectionName, formatId(data));
  await setDoc(docRef, copy);
}
/**
 * Converts Date to string formatted as yyyy-mm-dd hh:mm:ss
 * @param date
 * @returns
 */
function formatDate(date: Date) {
  const y = pad(4, date.getFullYear());
  const m = pad(2, date.getMonth());
  const d = pad(2, date.getDay());
  const h = pad(2, date.getHours());
  const mi = pad(2, date.getMinutes());
  const s = pad(2, date.getSeconds());
  return `${y}-${m}-${d} ${h}:${mi}:${s}`;
}
async function readFromFirestore(
  db: any,
  companyId: string,
  metricName: string
) {
  const colRef = query(
    collection(db, collectionName),
    where("companyId", "==", companyId),
    where("metricName", "==", metricName)
  );
  const querySnapshot = await getDocs(colRef);
  const result = querySnapshot.docs.map((d) => {
    const data = d.data();
    data.updatedAt = formatDate(data.updatedAt.toDate());
    return data;
  });
  return result;
}
async function deleteFromFirestore(db: any, data: MetricValue) {
  const docRef = doc(db, collectionName, formatId(data));
  await deleteDoc(docRef);
}

export { writeToFirestore, readFromFirestore, deleteFromFirestore };
