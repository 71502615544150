import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
// @material-ui/icons

 
// core components
import styles from "assets/jss/material-dashboard-pro-react/components/cardHeaderStyle.js";

const useStyles: any = makeStyles(styles as any);

interface CardHeaderProps {
  className: string;
  color: "warning" | "success" | "danger" | "info" | "primary" | "rose";
  plain: boolean;
  image: boolean;
  contact: boolean;
  signup: boolean;
  stats: boolean;
  icon: boolean;
  text: boolean;
  children: React.ReactNode;
}

export default function CardHeader(props: any) {
  const classes = useStyles();
  const {
    className,
    children,
    color,
    plain,
    image,
    contact,
    signup,
    stats,
    icon,
    text,
    ...rest
  } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[`${color}CardHeader`]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderImage]: image,
    [classes.cardHeaderContact]: contact,
    [classes.cardHeaderSignup]: signup,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [classes.cardHeaderText]: text,
    [className]: className !== undefined,
  });
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
}
