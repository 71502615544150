import { addId, fetchPastInputs } from "../commonHelpers";

export type AssetId = {
  /**
   * for targets, always set
   * for processes,
   *  - null
   *     - if single target
   *     - if it is the root portfolio process
   *  - set
   *     - if portfolio process for single target
   */
  companyId: string | null;
  /**
   * for targets, always null
   * for processes, always set
   */
  opportunityId: string | null;

  /**
   * compound id (companyId, opportunityID)
   * synthetically created in the frontend for easier overall id handling
   */
  id: string;
};

type GenericComponentOption = AssetId;

export type GenericComponentDayOption = GenericComponentOption & {
  days: number;
};

type QuarterlySourcingComponentOption = GenericComponentDayOption & {
  geography: string;
  type: string;
};

type WeeklyComponentDayOption = GenericComponentDayOption & {
  assetName: string;
  fund: string;
  description: string;
};

export type WeeklyElevateComponentOption = WeeklyComponentDayOption & {
  projectId: string;
  invoiceable: boolean;
};

type QuarterlySourcingFireStoreState = {
  owner: string;
  quarter_for: string;
  sourcingMeeting: QuarterlySourcingComponentOption[];
  insert_time?: string;
};

type WeeklyFireStoreState = {
  owner: string;
  week_for: string;
  admin: WeeklyComponentDayOption[];
  sourcing: WeeklyComponentDayOption[];
  building: WeeklyComponentDayOption[];
  workload: string;
  _insert_time?: string;
  isApproved?: boolean;
};

type ComponentState<T extends GenericComponentOption> = {
  options: T[];
  selection: T[];
  deleted: T[];
  isUpdated: boolean;
  isLoading: boolean;
  isError: boolean;
};

// TODO: this should be substituted with backend api
function getQuarterlyOptions(): Promise<QuarterlySourcingComponentOption[]> {
  const options: QuarterlySourcingComponentOption[] = [
    {
      geography: "Sweden",
      type: "Generic",
      companyId: "se-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Sweden",
      type: "Direct",
      companyId: "se-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Sweden",
      type: "Portfolio",
      companyId: "se-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Finland",
      type: "Generic",
      companyId: "fi-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Finland",
      type: "Direct",
      companyId: "fi-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Finland",
      type: "Portfolio",
      companyId: "fi-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Norway",
      type: "Generic",
      companyId: "no-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Norway",
      type: "Direct",
      companyId: "no-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Norway",
      type: "Portfolio",
      companyId: "no-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Denmark",
      type: "Generic",
      companyId: "dk-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Denmark",
      type: "Direct",
      companyId: "dk-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Denmark",
      type: "Portfolio",
      companyId: "dk-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Germany",
      type: "Generic",
      companyId: "de-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Germany",
      type: "Direct",
      companyId: "de-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Germany",
      type: "Portfolio",
      companyId: "de-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Austria",
      type: "Generic",
      companyId: "at-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Austria",
      type: "Direct",
      companyId: "at-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Austria",
      type: "Portfolio",
      companyId: "at-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Switzerland",
      type: "Generic",
      companyId: "ch-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Switzerland",
      type: "Direct",
      companyId: "ch-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Switzerland",
      type: "Portfolio",
      companyId: "ch-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "BeNeLux",
      type: "Generic",
      companyId: "bx-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "BeNeLux",
      type: "Direct",
      companyId: "bx-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "BeNeLux",
      type: "Portfolio",
      companyId: "bx-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "United Kingdom",
      type: "Generic",
      companyId: "uk-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "United Kingdom",
      type: "Direct",
      companyId: "uk-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "United Kingdom",
      type: "Portfolio",
      companyId: "uk-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Other",
      type: "Generic",
      companyId: "intl-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Other",
      type: "Direct",
      companyId: "intl-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Other",
      type: "Portfolio",
      companyId: "intl-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "France",
      type: "Generic",
      companyId: "fr-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "France",
      type: "Direct",
      companyId: "fr-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "France",
      type: "Portfolio",
      companyId: "fr-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Rest of Europe",
      type: "Generic",
      companyId: "reu-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Rest of Europe",
      type: "Direct",
      companyId: "reu-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "Rest of Europe",
      type: "Portfolio",
      companyId: "reu-portfolio",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "USA",
      type: "Generic",
      companyId: "us-generic",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "USA",
      type: "Direct",
      companyId: "us-direct",
      opportunityId: null,
      days: 0,
    },
    {
      geography: "USA",
      type: "Portfolio",
      companyId: "us-portfolio",
      opportunityId: null,
      days: 0,
    }
  ].map(addId);
  return new Promise((resolve, reject) => resolve(options));
}

type SteppedComponentReducerActionType =
  | "SET_FETCHED_OPTIONS"
  | "SET_FETCHED_SELECTION"
  | "UPDATE_DAYS_SELECTION"
  | "CLEAR_ZERO_DAYS_SELECTION"
  | "SET_UPDATED"
  | "ADD_SELECTION"
  | "SET_LOADING"
  | "SET_ERROR";
export type SteppedReducerAction = {
  type: SteppedComponentReducerActionType;
  payload: any; // TODO: define typed payload for better safety.
};

function inputListReducer<T extends GenericComponentDayOption>(
  state: ComponentState<T>,
  action: SteppedReducerAction
): ComponentState<T> {
  switch (action.type) {
    case "SET_FETCHED_OPTIONS": {
      const idList = state.selection.map((c) => c.id);
      const reducedList = action.payload.fetchedOptions.filter(
        (c: T) => !idList.includes(c.id)
      );

      return { ...state, options: reducedList };
    }

    case "SET_FETCHED_SELECTION": {
      /**
       * After approval of previous week, the fetched selection is reset.
       * Similarly during backfill, the selection is changed whenever a user changes the reporting week.
       * To handle such a change, before resetting the selection, we must first move the last selection
       * into the options.
       */
      // selection from previous ralloc report
      const previousSelection = state.selection.map((entry) => {
        return { ...entry, days: 0 };
      });
      // we include previous selection back to options
      const optionsWithPreviousSelection = [
        ...state.options,
        ...previousSelection,
      ];
      // new report selection, we need to again filter it out from the options
      const newSelection = action.payload.fetchedSelection.map(
        (y: any) => y.id
      );
      const reducedOptionsList = optionsWithPreviousSelection.filter(
        (c: any) => !newSelection.includes(c.id)
      );
      return {
        ...state,
        selection: action.payload.fetchedSelection,
        options: reducedOptionsList,
      };
    }
    case "UPDATE_DAYS_SELECTION":
      return {
        ...state,
        selection: state.selection.map((c) => {
          if (c.id === action.payload.id) {
            return { ...c, days: action.payload.days };
          }
          return c;
        }),
        isUpdated: true,
      };

    case "CLEAR_ZERO_DAYS_SELECTION": {
      // Delete selections where days === 0
      const deletedEntries = state.selection.filter((c) => c.days === 0);
      return {
        ...state,
        options: [...state.options, ...deletedEntries],
        selection: state.selection.filter((c) => c.days > 0),
      };
    }

    case "SET_UPDATED":
      return {
        ...state,
        isUpdated: action.payload.isUpdated,
      };

    case "ADD_SELECTION": {
      return {
        ...state,
        options: state.options.filter(
          (option) => option.id !== action.payload.newSelection.id
        ),
        selection: [...state.selection, action.payload.newSelection],
        isUpdated: true,
      };
    }

    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case "SET_ERROR":
      return {
        ...state,
        isError: action.payload.isError,
      };

    default:
      return state;
  }
}

function getTotalDays(selection: GenericComponentDayOption[]): number {
  const reducer = (a: number, b: number) => a + b;
  const days = selection.map((x) => x.days).reduce(reducer, 0);
  return days;
}

function setLoading(
  dispatchFunctions: React.Dispatch<SteppedReducerAction>[],
  value: boolean
) {
  dispatchFunctions.forEach((dispatchFunction) => {
    dispatchFunction({
      type: "SET_LOADING",
      payload: { isLoading: value },
    });
  });
}

async function fetchPastQuarterlySourcingInputs(db: any, documentId: string) {
  const collectionName = "quarterly_sourcing_input";
  const result = (await fetchPastInputs(
    db,
    documentId,
    collectionName
  )) as QuarterlySourcingFireStoreState;
  return result;
}

export type {
  QuarterlySourcingComponentOption,
  WeeklyComponentDayOption,
  QuarterlySourcingFireStoreState,
  WeeklyFireStoreState,
  ComponentState,
};

export {
  getQuarterlyOptions,
  inputListReducer,
  getTotalDays,
  setLoading,
  fetchPastQuarterlySourcingInputs,
};
