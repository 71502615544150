import React from "react";
// @material-ui/core components
import { withStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import { SteppedReducerAction } from "views/Forms/steppedInput/SteppedComponentHelper";
import ElevateInput from "../ElevateInput";
import { Project, WeeklyElevateComponentState } from "../types";
import { ProjectListReducerAction } from "../reducer";

type Props = {
  buildCompany: WeeklyElevateComponentState;
  dispatchBuildCompany: React.Dispatch<SteppedReducerAction>;
  dispatchActiveProjectCache: React.Dispatch<ProjectListReducerAction>;
  checkIfEntryInTableExists: (project: Project) => boolean;
  tableRows: Array<any>;
};

function WeeklyInputStep2({
  buildCompany,
  dispatchBuildCompany,
  dispatchActiveProjectCache,
  checkIfEntryInTableExists,
  tableRows,
}: Props) {
  return (
    <form>
      <GridContainer justifyContent="center">
        <ElevateInput
          tableRows={tableRows}
          tableHead={[
            "Asset Name",
            "Project Name",
            "Invoiceable",
            "Actions",
            "Days",
          ]}
          labelText="Target/Process Name"
          labelUnits="Days"
          inputSteps={0.25}
          companyList={buildCompany}
          dispatchCompanyList={dispatchBuildCompany}
          dispatchActiveProjectCache={dispatchActiveProjectCache}
          checkIfEntryInTableExists={checkIfEntryInTableExists}
          categoryOptions={["Onboarding", "Sparring Session", "Project"]}
          emptyMessage="Your report is empty."
          instruction=""
          secondInstruction=""
        />
      </GridContainer>
    </form>
  );
}

export default withStyles({})(WeeklyInputStep2);
