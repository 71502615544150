import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Button from "components/CustomButtons/Button";
import React from "react";
import { emptyProject } from "./ElevateInput";
import { Project } from "./types";

type Props = {
  openFormInput: boolean;
  // TODO: search if there is a rule specifically for function signatures
  // eslint-disable-next-line no-unused-vars
  closeFormInput: () => void;
  initName: string;
  // eslint-disable-next-line no-unused-vars
  createNewProject: (project: Project) => Promise<boolean>;
  categoryOptions: string[];
};

export default function CreateProjectDialog(props: Props) {
  const [submitting, setSubmitting] = React.useState(false);
  const [input, setInput] = React.useState<Project>(emptyProject);
  const {
    openFormInput,
    closeFormInput,
    initName,
    createNewProject,
    categoryOptions,
  } = props;

  React.useEffect(() => {
    setInput({ ...input, name: initName });
  }, [initName]);
  return (
    <Dialog
      fullScreen={false}
      disableEscapeKeyDown
      open={openFormInput}
      onClose={() => {
        setInput(emptyProject);
        closeFormInput();
      }}
      aria-labelledby="project-input"
      scroll="body"
      maxWidth="md"
    >
      <DialogTitle id="project-input">
        {/* TODO: browser console error when typography is used as a direct child
                  by default, h4 is used here, which looks very small
        */}
        <div>
          <Typography variant="h3" key={0}>
            Add a new Project
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            autoFocus
            id="name"
            value={input.name}
            onChange={(e) => {
              setInput({ ...input, name: e.target.value });
            }}
            label="Name"
            type="text"
            fullWidth
            variant="standard"
          />
          <FormControl fullWidth variant="standard">
            <InputLabel id="select-week">Category</InputLabel>
            <Select
              id="select-category"
              value={input.category}
              onChange={(e) => {
                const value = e.target.value as string;
                setInput({ ...input, category: value });
              }}
            >
              {categoryOptions.map((category) => {
                return (
                  <MenuItem value={category} key={category}>
                    {category}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            style={{ marginTop: "12px" }}
            multiline
            rows={3}
            autoFocus
            id="description"
            value={input.description}
            onChange={(e) => {
              setInput({ ...input, description: e.target.value });
            }}
            label="Description"
            type="text"
            fullWidth
            variant="standard"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setInput(emptyProject);
            closeFormInput();
          }}
          key={0}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={async () => {
            setSubmitting(true);
            const created = await createNewProject({ ...input });
            setSubmitting(false);
            if (created) {
              setInput(emptyProject);
            }
          }}
          key={1}
          disabled={submitting}
        >
          {submitting ? "Adding ..." : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
