import React from "react";
import App from "app.js";
import ReactDOM from "react-dom/client";

// The styledEngineProvider is needed for the styling to work with makestyles and usestyles as it will override the root mui styling with our styling
// The side effect is that there are some Components like Alert, which we want to keep the root styling will also be overrided
// I have tried to wrap styledEngineProvider around the every needed Components and try to save all Components with no success because our styling is too nested
// we have to accept that some standard styling will not work with out current Styling way ! Long live Muiv5 !
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import theme from "assets/theme";
import AppProviders from "./AppProviders.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppProviders>
    {/* Themeprovider is needed for makestyles in Admin.js to run */}
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </ThemeProvider>
  </AppProviders>
);
