import {
  roseColor,
  infoColor,
} from "assets/jss/material-dashboard-pro-react.js";

const weeklyInput = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    padding: "3200px",
    float: "right !important",
  },
  forwardHighlight: {
    color: roseColor[0],
  },
  backwardsHighlight: {
    color: infoColor[0],
  },
  infoMuiBackGround: {
    "& .MuiPaper-root": {
      backgroundColor: "rgb(229, 246, 253)",
    }
  }
};


export default weeklyInput;
