import { FormControl, InputLabel, TextField } from "@mui/material";
import * as React from "react";
import style from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { Validity } from "./BenchmarkInput";

const useStyles = makeStyles(style as any);

function DatePicker(props: {
  isValid: Validity;
  value: string | null;
  onChange: (value: string) => void;
}) {
  const { value, onChange, isValid } = props;
  const classes = useStyles();
  const labelStyle = `${classes.labelRoot} ${classNames({
    [` ${classes.labelRootSuccess}`]: isValid === "YES",
    [` ${classes.labelRootError}`]: isValid === "NO",
  })}`;
  return (
    <FormControl fullWidth className={classes.formControl} variant="standard">
      <InputLabel
        shrink
        className={labelStyle}
        htmlFor="benchmark-input-date-picker"
      >
        <span>Date</span>
      </InputLabel>
      <TextField
        id="datepicker_input"
        type="date"
        value={value}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        variant="standard"
      />
    </FormControl>
  );
}

export { DatePicker };
