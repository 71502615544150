import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
// @material-ui/icons

// core components
import styles from "assets/jss/material-dashboard-pro-react/components/cardBodyStyle.js";
 
const useStyles = makeStyles(styles as any);

interface CardBodyProps {
  className: string;
  background: boolean;
  plain: boolean;
  formHorizontal: boolean;
  pricing: boolean;
  signup: boolean;
  color: boolean;
  profile: boolean;
  calendar: boolean;
  children: React.ReactNode;
}

export default function CardBody(props: any) {
  const classes = useStyles();
  const {
    className,
    children,
    background,
    plain,
    formHorizontal,
    pricing,
    signup,
    color,
    profile,
    calendar,
    ...rest
  } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyBackground]: background,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyFormHorizontal]: formHorizontal,
    [classes.cardPricing]: pricing,
    [classes.cardSignup]: signup,
    [classes.cardBodyColor]: color,
    [classes.cardBodyProfile]: profile,
    [classes.cardBodyCalendar]: calendar,
    [className]: className !== undefined,
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}
