// Contains functions that are used by at least two forms.
import React from "react";
import PropTypes from "prop-types";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  Firestore,
  serverTimestamp,
} from "firebase/firestore";

import { preventSubmitIfUserView } from "context/auth-context";

import { WeekInfo } from "./weeklyElevateInput/WeeklyElevateInput";

import {
  getRowColor,
  daysControl,
  createBadge,
} from "./steppedInput/weeklyTablesHelpers";

interface TimeResponse {
  weeklyInput: WeekInfo;
  monthlyInput: { inputMonth: string; inputYear: string };
  quarterlyInput: { inputQuarter: string; inputYear: string };
  yearlyInput: { inputYear: string };
}

async function fetchPastInputs(
  db: Firestore,
  documentId: string,
  collectionName: string
): Promise<any> {
  const collectionComposite =
    process.env.REACT_APP_FIREBASE_DB_PREFIX + collectionName;
  const docRef = doc(db, collectionComposite, documentId);

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

export async function checkIfPastInputExists(
  db: Firestore,
  userEmail: string,
  weeks: string[],
  collectionName: string
): Promise<boolean[]> {
  const collectionComposite =
    process.env.REACT_APP_FIREBASE_DB_PREFIX + collectionName;
  const chunkSize = 10;
  const found: string[] = [];
  const promises: Promise<any>[] = [];
  for (let i = 0; i < weeks.length; i += chunkSize) {
    const chunk = weeks.slice(i, i + chunkSize);
    const q = query(
      collection(db, collectionComposite),
      where("owner", "==", userEmail),
      where("week_for", "in", chunk)
    );
    promises.push(getDocs(q));
  }
  const resolved = await Promise.all(promises);
  resolved.forEach((documents) => {
    documents.forEach((document: any) => found.push(document.data().week_for));
  });
  return weeks.map((week) => found.includes(week));
}

/** generates frontend specific unique id for each asset(either process or targer) * */
export function addId(asset: any): any {
  let id: string;
  if (asset.opportunityId == null) {
    // targets
    id = asset.companyId;
  } else if (asset.companyId == null) {
    // non-portfolio processes(they have single targets), or
    // root portfolio process
    id = asset.opportunityId;
  } else {
    // portfolio processes for individual targets
    id = `${asset.opportunityId}_${asset.companyId}`;
  }
  function getNullIfUndefined(possibleUndefined: any) {
    if (possibleUndefined === undefined) {
      return null;
    }
    return possibleUndefined;
  }
  return {
    ...asset,
    opportunityId: getNullIfUndefined(asset.opportunityId),
    companyId: getNullIfUndefined(asset.companyId),
    id,
  };
}

// Fetches companies out of the Firestore company cache. The cache is
// updated every 10 minutes.
async function fetchCompanyCache(
  db: Firestore,
  documentId: string
): Promise<any> {
  const collectionName = `${process.env.REACT_APP_FIREBASE_DB_PREFIX}company_cache`;
  const docRef = doc(db, collectionName, documentId);

  return getDoc(docRef).then((docSnap) => {
    if (docSnap.exists()) {
      return docSnap.data().choices.map((x: any) => {
        let { fundName } = x;
        if (fundName === null) {
          fundName = "No Fund Info";
        }
        return addId({
          assetName: x.companyName,
          fund: fundName,
          companyId: x.companyId,
          opportunityId: x.opportunityId,
        });
      });
    }
    return null;
  });
}

// Helpers to turn state into Table rows with buttons
function getTableRows(reducerState: any, dispatchFun: any): any[] {
  if (
    reducerState.selection === null ||
    reducerState.selection === [] ||
    reducerState.selection.length === 0
  ) {
    return [];
  }
  const assetData: any[] = reducerState.selection.map((c: any, index: any) => {
    const container: any = {};
    container.color = getRowColor(c.days);
    container.data = [
      c.assetName,
      daysControl(c.id, c.days, dispatchFun, 0.25),
      createBadge(index, c.days),
    ];
    return container;
  });
  return assetData;
}

function getTableRowsMeeting(reducerState: any, dispatchFun: any): any[] {
  if (
    reducerState.selection === null ||
    reducerState.selection === [] ||
    reducerState.selection.length === 0
  ) {
    return [];
  }
  const assetData: any[] = reducerState.selection.map((c: any, index: any) => {
    const container: any = {};
    container.color = getRowColor(c.days);
    container.data = [
      c.geography,
      c.type,
      daysControl(c.id, c.days, dispatchFun, 0.5),
      createBadge(index, c.days),
    ];
    return container;
  });
  return assetData;
}

async function sendToFirestore(
  timeLabel: string,
  timeFor: any,
  user: any,
  db: any,
  collectionV: string,
  state: any,
  documentId: string
): Promise<void> {
  // send tabular state to firestore
  const payload: any = {
    ...state,
    owner: user.email,
    owner_uid: user.uid,
    [timeLabel]: timeFor,
    _insert_time: serverTimestamp(),
  };
  const collectionName: string =
    process.env.REACT_APP_FIREBASE_DB_PREFIX + collectionV;
  const docRef: any = doc(db, collectionName, documentId);
  await setDoc(docRef, payload);
}

// Used by the wizard components: sourcing & weekly components
async function sendStateController(
  timeLabel: string,
  timeFor: string,
  user: any,
  dbFirestore: Firestore,
  collectionV: string,
  firestoreState: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  note: any,
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>,
  dispatchFunctions: Array<React.Dispatch<any>>,
  callbackOnSuccess: () => void,
  documentId: string
) {
  preventSubmitIfUserView();
  setLoading(true);

  try {
    await sendToFirestore(
      timeLabel,
      timeFor,
      user,
      dbFirestore,
      collectionV,
      firestoreState,
      documentId
    );
    if (callbackOnSuccess !== undefined && callbackOnSuccess !== null) {
      callbackOnSuccess();
    }
  } catch (error) {
    // @TODO: better error handling here
    console.error(error);
    note.sendNotification(
      `Could not save your report successfully. Please resubmit your report and contact an admin. Error: ${JSON.stringify(
        error
      )}.`,
      "error"
    );
  }
  setLoading(false);
  setOpenDialog(true);
  dispatchFunctions.forEach((dispatchFunction) => {
    dispatchFunction({ type: "CLEAR_ZERO_DAYS_SELECTION" });
    dispatchFunction({
      type: "SET_UPDATED",
      payload: { isUpdated: false },
    });
  });
}

async function fetchBackendTime(user: any): Promise<TimeResponse> {
  return user
    .getIdToken(true)
    .then((token: string) => {
      return {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    })
    .then((requestOptions: any) => {
      return fetch(`${process.env.REACT_APP_BACKEND_URL}/time`, requestOptions);
    })
    .then((response: Response) => response.json());
}

async function fetchInputQuarter(
  user: any,
  setInputQuarter: React.Dispatch<React.SetStateAction<string>>,
  setInputYear: React.Dispatch<React.SetStateAction<string>>
) {
  const quarter = fetchBackendTime(user).then((data: any) => {
    setInputQuarter(data.quarterlyInput.inputQuarter);
    setInputYear(data.quarterlyInput.inputYear);
    return `${data.quarterlyInput.inputYear}Q${data.quarterlyInput.inputQuarter}`;
  });
  return quarter;
}

/**
 * prop for all the input form components and it's default
 */
const InputFormPropType = {
  isStackedView: PropTypes.bool,
};
const InputFormDefaultProps = {
  isStackedView: false,
};

export {
  getTableRows,
  getTableRowsMeeting,
  fetchPastInputs,
  sendStateController,
  fetchInputQuarter,
  fetchCompanyCache,
  fetchBackendTime,
  InputFormPropType,
  InputFormDefaultProps,
};
