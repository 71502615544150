import React from "react";

// @material-ui/core components
import { CircularProgress, Typography } from "@mui/material";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@mui/styles";

 
type SpinnerProps = {
  label: string;
  size: string;
  position: string;
  iconColor: string;
};

const useStyles = makeStyles({
  root: {
    minHeight: (props: SpinnerProps) =>
      props.position === "fullPage" ? "100vh" : "",
    marginTop: (props: SpinnerProps) =>
      props.position === "component" ? "18px" : "",
  },
  labelText: {
    textAlign: "center",
    marginTop: "8px",
  },
});

const Spinner: React.FC<any> = (props) => {
  const classes = useStyles(props);
  const sizes: any = { large: 80, medium: 50, small: 30 };
  return (
    <GridContainer
      justifyContent="center"
      spacing={2}
      direction="column"
      alignItems="center"
      className={classes.root}
    >
      <GridItem xs={6}>
        <CircularProgress size={sizes[props.size]} />
      </GridItem>
      <GridItem xs={6}>
        <Typography className={classes.labelText}>{props.label}</Typography>
      </GridItem>
    </GridContainer>
  );
};

Spinner.defaultProps = {
  iconColor: "gray",
};

export { Spinner };
