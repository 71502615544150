import React from "react";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

 
 
const styles = {
  grid: {
    margin: "0 -15px",
    width: "calc(100% + 30px)",
    // '&:before,&:after':{
    //   display: 'table',
    //   content: '" "',
    // },
    // '&:after':{
    //   clear: 'both',
    // }
  },
};

const useStyles = makeStyles(styles);

interface GridContainerProps {
  className: string;
  children: React.ReactNode;
  justifyContent: string;
}

export default function GridContainer(props: any) {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </Grid>
  );
}
