// firestore
import * as React from "react";
import { Spinner } from "components/Spinner/Spinner";
import { useInputContext } from "context/input-context";
import { useAuth } from "context/auth-context.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

export default function StackedInput() {
  const auth = useAuth();
  const { state, dispatch } = useInputContext();
  const unfinished = {
    message: "You Have Pending Reports 📝",
    help: `Hi ${auth.user.displayName}, some of your reports are due. Please submit all
    forms below. Once all reports are submitted, you will receive no further reminders in
    Teams.`,
  };
  const completed = {
    message: "Nothing to do here 🏖️",
    help: "You submitted all reports. Thank you and have a nice day!",
  };
  const error = {
    message: "Ran into error while trying to load the stacked view.",
    help: "Try going to individual views instead!",
  };
  const instruction = (message: string, help: string) => {
    return (
      <Card>
        <CardHeader>
          <h3 style={{ textAlign: "center" }}>{message}</h3>
        </CardHeader>
        <CardBody> {help} </CardBody>
      </Card>
    );
  };
  const view = () => {
    if (state.error === true) {
      return instruction(error.message, error.help);
    } else if (state.loading === true) {
      return (
        <Spinner
          size="medium"
          position="component"
          label="Loading pending reports ..."
        />
      );
    } else {
      const toShow = state.stack.filter((s) => s.incomplete > 0);
      if (toShow.length > 0)
        return (
          <>
            <GridContainer justifyContent="center">
              <GridItem xs={12} sm={10}>
                {instruction(unfinished.message, unfinished.help)}
              </GridItem>
            </GridContainer>
            {toShow.map((s) => s.component)}
          </>
        );
      else return instruction(completed.message, completed.help);
    }
  };
  return <>{view()}</>;
}
