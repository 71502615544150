import { makeStyles } from "@mui/styles";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import selectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import inputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import React from "react";
import classNames from "classnames";

const useSelectStyles = makeStyles(selectStyle as any);
const useInputStyles = makeStyles(inputStyle as any);

export default function SingleSelect(props: {
  title: string;
  data: any[];
  value: string;
  callbackOnSelect: (value: string) => void;
}) {
  const { title, data, callbackOnSelect, value } = props;
  const handleSimple = (event: any) => {
    callbackOnSelect(event.target.value);
  };
  const selectClasses = useSelectStyles();
  const inputClasses = useInputStyles();
  const Options = data.map((entry, index) => {
    return (
      <MenuItem
        key={index}
        classes={{
          root: selectClasses.selectMenuItem,
          selected: selectClasses.selectMenuItemSelected,
        }}
        value={entry}
      >
        {entry}
      </MenuItem>
    );
  });
  const emptyValue = value == null || value == "";
  const labelStyle = `${inputClasses.labelRootSingleSelect} ${classNames({
    [` ${inputClasses.labelRootSuccess}`]: !emptyValue,
  })}`;
  return (
    <FormControl
      fullWidth
      className={selectClasses.selectFormControl}
      variant="standard"
    >
      <InputLabel
        shrink={!emptyValue}
        className={labelStyle}
        htmlFor={`benchmark-input-${title}`}
      >
        {title}
      </InputLabel>
      <Select
        id={`benchmark-input-${title}`}
        MenuProps={{
          className: selectClasses.selectMenu,
        }}
        classes={{
          select: selectClasses.selectSingleSelect,
        }}
        value={value == null ? "" : value}
        onChange={handleSimple}
        inputProps={{
          name: "simpleSelect",
          id: "simple-select",
        }}
      >
        {Options}
      </Select>
    </FormControl>
  );
}
