import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

import Table from "components/Table/Table.js";

import style from "assets/jss/material-dashboard-pro-react/components/dayInput.js";

const useStyles = makeStyles(style);

export default function DayTable(props) {
  const { tableRows, tableHead } = props;
  const badgeColumn = tableHead.length - 1;
  const classes = useStyles();
  return (
    <Table
      striped
      hover
      tableHead={tableHead}
      tableData={tableRows}
      customCellClasses={[classes.right]}
      customClassesForCells={[badgeColumn]}
      customHeadCellClasses={[classes.right]}
      customHeadClassesForCells={[badgeColumn]}
    />
  );
}

DayTable.propTypes = {
  tableRows: PropTypes.array.isRequired,
  tableHead: PropTypes.array.isRequired,
};
