import React from "react";

// @material-ui/core components
import { withStyles } from "@mui/styles";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import DayInput from "components/DayInput/DayInput.js";
import { InputLabel, Select, MenuItem, FormControl, Box } from "@mui/material";
import {
  ComponentState,
  GenericComponentDayOption,
  SteppedReducerAction,
} from "../SteppedComponentHelper";

type Props = {
  adminCompany: ComponentState<GenericComponentDayOption>;
  dispatchAdminCompany: React.Dispatch<SteppedReducerAction>;
  classes: any;
  workload: string;
  tableRows: Array<any>;
  setWorkload: (value: string) => void;
};

function WeeklyInputStep3({
  adminCompany,
  dispatchAdminCompany,
  classes,
  workload,
  tableRows,
  setWorkload,
}: Props) {
  const question = "Do you have capacity to work on new processes?";
  const answerList = [
    {
      value: "low",
      label: "Yes.",
    },
    {
      value: "average",
      label: "Not really, but could handle if important.",
    },
    {
      value: "at capacity",
      label: "No, totally swamped.",
    },
  ];

  const handleChange = (e: any) => {
    setWorkload(e.target.value);
  };

  function optionsList(options: any) {
    return options.map((item: any, key: any) => (
      <MenuItem
        key={`item-${key}`}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value={item.value}
      >
        {item.label}
      </MenuItem>
    ));
  }

  return (
    <GridContainer justifyContent="center">
      <DayInput
        tableRows={tableRows}
        tableHead={["Asset Name", "Actions", "Days"]}
        companyList={adminCompany}
        dispatchCompanyList={dispatchAdminCompany}
        emptyMessage="Your report is empty."
        labelText="Activity Name"
        labelUnits="Days"
        inputSteps={0.25}
      >
        <GridItem xs={12}>
          <Box mb={5} />
        </GridItem>
        <GridItem xs={10} md={2}>
          <h5 className={classes.infoText}>{question}</h5>
        </GridItem>
        <GridItem xs={10} md={8}>
          <FormControl
            fullWidth
            className={classes.selectFormControl}
            data-testid="weekly-workload"
            variant="standard"
          >
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Select Answer
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={workload}
              onChange={handleChange}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select",
              }}
            >
              {optionsList(answerList)}
            </Select>
          </FormControl>
        </GridItem>
      </DayInput>
    </GridContainer>
  );
}

export default withStyles(selectStyles as any)(WeeklyInputStep3);
