import {
  query,
  where,
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  writeBatch,
  serverTimestamp,
} from "firebase/firestore";
import type { Project } from "./types";

const projectCollection = `${process.env.REACT_APP_FIREBASE_DB_PREFIX}elevate_project_input`;

export async function getProjects(
  // db: firestore.firestore.Firestore, TODO add type
  db: any,
  companyId: string
): Promise<Project[]> {
  const q = query(
    collection(db, projectCollection),
    where("companyId", "==", companyId)
  );
  const querySnapshot = await getDocs(q);
  // eslint-disable-next-line no-array-constructor
  const result = Array();
  querySnapshot.forEach((doc) => result.push(doc.data()));
  return result;
}

export async function getProject(
  db: any,
  projectId: string
): Promise<Project | null> {
  const docRef = doc(db, projectCollection, projectId);
  const project = await getDoc(docRef);
  if (project.exists()) {
    return project.data() as Project;
  }
  return null;
}

export async function getProjectsById(
  db: any,
  ids: string[]
): Promise<Project[]> {
  if (ids.length === 0) {
    return [];
  }

  // Firestore supports up to 10 ids being filtered with an "in" clause.
  // Sometimes, users submit more than 10 different projects. We split the ids
  // into batches for that reason.
  const batches = [];

  while (ids.length) {
    const batch = ids.splice(0, 10);
    const q = query(
      collection(db, projectCollection),
      where("id", "in", batch)
    );
    const projects = getDocs(q).then((response) =>
      response.docs.map((doc) => doc.data() as Project)
    );
    batches.push(projects);
  }

  const allProjects = Promise.all(batches).then((content) => content.flat());
  return allProjects;
}

export async function createProject(db: any, project: Project) {
  const docRef = doc(db, projectCollection, project.id);
  await setDoc(docRef, project);
  return project;
}

// eslint-disable-next-line no-unused-vars
async function updateProjects(db: any, records: Project[]) {
  const batch = writeBatch(db);
  records.forEach((d) => {
    const docRef = doc(db, projectCollection, d.id);
    batch.update(docRef, { ...doc, _update_time: serverTimestamp() });
  });
  await batch.commit();
}
