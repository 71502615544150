import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

// core components
import Tooltip from "@mui/material/Tooltip";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

// custom components
import CustomInput from "components/CustomInput/CustomInput";
import { Spinner } from "components/Spinner/Spinner";
import DayTable from "components/DayTable/DayTable.js";

import selectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import style from "assets/jss/material-dashboard-pro-react/components/dayInput.js";

const useStyles = makeStyles({ ...style, ...selectStyle });

export default function DayInput(props) {
  const {
    companyList,
    dispatchSourcingMeeting,
    emptyMessage,
    instruction,
    secondInstruction,
    labelText,
    labelUnits,
    inputSteps,
    tableRows,
    tableHead,
  } = props;

  const [meetings, setMeetings] = useState(0);
  const [meetingsValid, setMeetingsValid] = useState("");
  const [geography, setGeography] = useState("");
  const [type, setType] = useState("");
  const [typeValid, setTypeValid] = useState("");
  const classes = useStyles();

  const typeItems = (availableTypes) => {
    return availableTypes.map((item, key) => (
      <MenuItem
        key={`type-${key}`}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value={item.type}
      >
        {item.type}
      </MenuItem>
    ));
  };

  const typeList = (options) => {
    return options.filter((x) => x.geography === geography);
  };

  const geographyList = (options) => {
    // sort the select list alphabetically
    // this works well with Autocomplete's sorting. Depending on which
    // component is loaded, the options are sorted accordingly.
    const distinctGeographies = Array.from(
      new Set(options.map((x) => x.geography))
    );

    distinctGeographies.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });

    return distinctGeographies.map((item, key) => (
      <MenuItem
        key={`geography-${key}`}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value={item}
      >
        {item}
      </MenuItem>
    ));
  };

  const verifyQuarter = (value, inputSteps) => {
    // second terms catches (empty) strings
    if (
      value % inputSteps === 0 &&
      !Number.isNaN(Number.parseInt(value)) &&
      value !== 0 &&
      value > 0
    ) {
      return true;
    }
    return false;
  };

  // Add a selected asset with the respective Days Input to the selection.
  const addAsset = () => {
    if (meetingsValid === "success") {
      // Uniquely identify meeting with geography and type
      const asset = companyList.options.filter(
        (x) => x.geography === geography && x.type === type
      );

      if (asset.length === 0) {
        setTypeValid("error"); // TODO highlight color in select component
      } else {
        // reset select fields
        setGeography("");
        setType("");

        const newItem = { ...asset[0], days: meetings };

        dispatchSourcingMeeting({
          type: "ADD_SELECTION",
          payload: { newSelection: newItem },
        });

        // remove added asset from autocomplete option
        setMeetings(0); // clear days input
        setMeetingsValid("error");
      }
    } else if (meetingsValid !== "success") {
      setMeetingsValid("error");
    }
  };

  // controller that checks validation state before updating inputs.
  const change = (stateName, newValue, validationSuccessful) => {
    if (stateName === "meetings") {
      setMeetings(Number.parseFloat(newValue));
      if (validationSuccessful === true) {
        setMeetingsValid("success");
      } else if (validationSuccessful === false) {
        setMeetingsValid("error");
      } else {
        throw new Error("Invalid validation result.");
      }
    } else if (stateName === "type") {
      setType(newValue);
      setTypeValid("success");
    } else {
      throw new Error("Invalid state to be updated");
    }
  };

  const compositeLabelUnits = <span>{labelUnits}</span>;

  return (
    <GridContainer justifyContent="center" spacing={4}>
      <GridItem className={classes.dayGridItem} xs={10} sm={3}>
        <FormControl
          fullWidth
          className={classes.selectFormControl}
          data-testid="quarterly-meeting-select-geo"
          variant="standard"
        >
          <InputLabel
            htmlFor="simple-select-geography"
            className={classes.selectLabel}
          >
            Select Target Geography
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.selectDay,
            }}
            value={geography}
            onChange={(e) => {
              setGeography(e.target.value);
            }}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select-geography",
            }}
          >
            {geographyList(companyList.options)}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem className={classes.dayGridItem} xs={10} sm={3}>
        <FormControl
          fullWidth
          className={classes.selectFormControl}
          data-testid="quarterly-meeting-select-type"
          variant="standard"
        >
          <InputLabel
            htmlFor="simple-select-meeting"
            className={classes.selectLabel}
          >
            {typeList(companyList.options).length > 0
              ? "Select Type of Meeting"
              : "Select Target Geography first"}
          </InputLabel>
          <Select
            disabled={!(typeList(companyList.options).length > 0)}
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.selectDay,
            }}
            value={type}
            onChange={(e) => {
              change("type", e.target.value, true);
            }}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select-meeting",
            }}
          >
            {typeItems(typeList(companyList.options))}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem xs={3} sm={1}>
        <CustomInput
          success={meetingsValid === "success"}
          error={meetingsValid === "error"}
          labelText={compositeLabelUnits}
          id="meetings"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: "number",
            value: meetings,
            onChange: (event) => {
              change(
                "meetings",
                event.target.value,
                verifyQuarter(event.target.value, inputSteps)
              );
            },
          }}
          numberProps={{
            step: inputSteps,
            min: 0,
          }}
        />
      </GridItem>

      <GridItem className={classes.addGridItem} xs={2} sm={1}>
        <Tooltip title="Add Input" aria-label="add-input">
          <Button
            color="primary"
            className={classes.addAssetButton}
            onClick={() => addAsset()}
            data-testid="meeting-add"
          >
            Add
          </Button>
        </Tooltip>
      </GridItem>

      {companyList.isLoading ? (
        <GridItem xs={10} sm={10}>
          <Spinner
            size="medium"
            position="component"
            label="Loading past inputs"
          />
        </GridItem>
      ) : (
        <GridItem xs={12} sm={10}>
          {tableRows.length > 0 ? (
            <DayTable tableRows={tableRows} tableHead={tableHead} />
          ) : (
            <h5>
              {emptyMessage}
              <br />
            </h5>
          )}
          <h5>
            <small>{instruction}</small>
            {secondInstruction ? (
              <>
                <br />
                <small>{secondInstruction}</small>
              </>
            ) : (
              ""
            )}
          </h5>
        </GridItem>
      )}
      {props.children}
    </GridContainer>
  );
}

DayInput.propTypes = {
  companyList: PropTypes.object,
  dispatchSourcingMeeting: PropTypes.func,
  emptyMessage: PropTypes.string,
  instruction: PropTypes.string,
  secondInstruction: PropTypes.string,
  labelText: PropTypes.string,
  labelUnits: PropTypes.string,
  inputSteps: PropTypes.number,
  tableRows: PropTypes.array,
  tableHead: PropTypes.array.isRequired,
};
