import * as React from "react";

// import React, { useReducer, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import useUnload from "context/use-unload.js";
import { useSnackbar } from "context/snackbar-context.js";
import style from "assets/jss/material-dashboard-pro-react/views/weeklyInput.js";

// firestore
import app from "firebaseConfig";
import { getFirestore } from "firebase/firestore";

// core components

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import WeeklyInputDialog from "components/WeeklyInputDialog/WeeklyInputDialog.js";
import usePrompt from "components/Prompt/usePrompt";

import { useAuth } from "context/auth-context.js";

import { InferProps } from "prop-types";
import { InputActionType, useInputContext } from "context/input-context";
import DayWizard from "components/DayWizard/DayWizard";
import QuarterlySourcingInputStep from "./steps/QuarterlySourcingInputStep";
import {
  fetchPastInputs,
  getTableRowsMeeting,
  sendStateController,
  fetchInputQuarter,
  InputFormPropType,
  InputFormDefaultProps,
} from "../commonHelpers";
import {
  getQuarterlyOptions,
  inputListReducer,
  getTotalDays,
  setLoading,
  QuarterlySourcingFireStoreState,
  QuarterlySourcingComponentOption,
  ComponentState,
  fetchPastQuarterlySourcingInputs,
} from "./SteppedComponentHelper";
import { componentIds } from "../stackedInput/StackedInputHelper";

const getInitAction = (): ComponentState<QuarterlySourcingComponentOption> => ({
  options: [],
  selection: [],
  deleted: [],
  isUpdated: false,
  isLoading: false,
  isError: false,
});

const useStyles = makeStyles(style as any); // TODO: remove type cast

function QuarterlySourcingInput({
  isStackedView,
}: InferProps<typeof InputFormPropType>) {
  const db = getFirestore(app);
  const auth = useAuth();
  const note = useSnackbar();
  const classes = useStyles();
  const inputContext = useInputContext();

  // Week for which the weeklyInput is done for

  const [inputQuarter, setInputQuarter] = React.useState("");
  const [inputYear, setInputYear] = React.useState("");

  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false); // shows "FINISH" button as loading
  const [isOpenDialog, setOpenDialog] = React.useState(false); // dialogue to Dashboard
  // Input for sourcing meetings
  const [sourcingMeeting, dispatchSourcingMeeting] = React.useReducer(
    inputListReducer,
    getInitAction()
  );

  React.useReducer(inputListReducer, getInitAction());

  const sourcingMeetingRows = getTableRowsMeeting(
    sourcingMeeting,
    dispatchSourcingMeeting
  );

  // warns user
  useUnload((e: any) => {
    if (sourcingMeeting.isUpdated) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  React.useEffect(() => {
    async function setPastInput(
      firestoreState: QuarterlySourcingFireStoreState,
      timeFor: string, // string encoded quarter
      sourcingMeetingOptions: QuarterlySourcingComponentOption[]
    ) {
      if (firestoreState !== null) {
        if (firestoreState.quarter_for === timeFor) {
          // TODO re-factor this spaghetti code.
          if (typeof firestoreState.sourcingMeeting === "undefined") {
            note.sendNotification(
              "Your Firestore Cache is corrupt. Please contact an admin and report this incident.",
              "warning"
            );
          } else {
            note.sendNotification(
              `You already submitted a Sourcing Meeting Report for this quarter. To edit your report, re-submit it.`,
              "info"
            );
            dispatchSourcingMeeting({
              type: "SET_FETCHED_SELECTION",
              payload: { fetchedSelection: firestoreState.sourcingMeeting },
            });
          }
        }
      }

      dispatchSourcingMeeting({
        type: "SET_FETCHED_OPTIONS",
        payload: { fetchedOptions: sourcingMeetingOptions },
      });
    }

    async function fetchChain() {
      setLoading([dispatchSourcingMeeting], true);
      const quarterFor = await fetchInputQuarter(
        auth.user,
        setInputQuarter,
        setInputYear
      );
      const documentId = `${auth.user.email}_${quarterFor}`;
      const [pastInput, sourcingMeetingOptions] = await Promise.all([
        fetchPastQuarterlySourcingInputs(db, documentId),
        getQuarterlyOptions(),
      ]);

      await setPastInput(pastInput, quarterFor, sourcingMeetingOptions);

      setLoading([dispatchSourcingMeeting], false);
    }

    fetchChain();
  }, [auth.user, db]);

  // This action is safe to be called on every submit,
  // A successfully submitted form is always considered complete in the current
  // logic. Reducer makes sure incompletecount >= 0
  const callbackOnSuccess = () => {
    inputContext.dispatch({
      type: InputActionType.DECREMENT_INCOMPLETE_COUNT,
      payload: componentIds.QUARTERLY_SOURCING,
    });
  };
  usePrompt(
    "You are about to leave the Quarterly Sourcing Meetings without having saved your inputs. Unsaved changes will be discarded. Do you want to continue?",
    sourcingMeeting.isUpdated
  );
  const backwardsInstruction = (
    <h4>
      In which Sourcing Meetings did you participate in{" "}
      <span className={classes.forwardHighlight}>Quarter {inputQuarter}</span>,{" "}
      {inputYear}? <br />
    </h4>
  );
  return (
    <>
      {!isStackedView && (
        <WeeklyInputDialog
          setOpenDialog={setOpenDialog}
          isOpenDialog={isOpenDialog}
          type="success"
          message="Report successfully submitted."
        />
      )}
      {
        // TODO this doesn't work anymore.
        // <Prompt
        //   when={sourcingMeeting.isUpdated}
        //   message="You are about to leave the Quarterly Sourcing Meetings without having saved your inputs. Unsaved changes will be discarded. Do you want to continue?"
        // />
      }
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={10}>
          <DayWizard
            isSubmitLoading={isSubmitLoading}
            timeInstruction={backwardsInstruction}
            steps={[
              {
                stepName: "Sourcing Meetings",
                component: (
                  <QuarterlySourcingInputStep
                    sourcingMeeting={sourcingMeeting}
                    dispatchSourcingMeeting={dispatchSourcingMeeting}
                    tableRows={sourcingMeetingRows}
                  />
                ),
              },
            ]}
            title="Quarterly Sourcing Meetings Report"
            finishButtonClick={() => {
              // This deletes 0 day inputs from firestore
              const firestoreState = {
                sourcingMeeting: sourcingMeeting.selection.filter(
                  (c) => c.days !== 0
                ),
              };
              if (firestoreState.sourcingMeeting.length === 0) {
                note.sendNotification(
                  "You are trying to submit an empty report. Please fill in the report before submitting.",
                  "error"
                );
                return;
              }
              const quarterFor = `${inputYear}Q${inputQuarter}`;
              sendStateController(
                "quarter_for",
                quarterFor,
                auth.user,
                db,
                "quarterly_sourcing_input",
                firestoreState,
                setIsSubmitLoading,
                note,
                setOpenDialog,
                [dispatchSourcingMeeting],
                callbackOnSuccess,
                `${auth.user.email}_${quarterFor}`
              );
            }}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}

QuarterlySourcingInput.propTypes = InputFormPropType;

QuarterlySourcingInput.defaultProps = InputFormDefaultProps;

export default QuarterlySourcingInput;
