import QuarterlySourcingInput from "views/Forms/steppedInput/QuarterlySourcingInput";

import StackedInput from "views/Forms/stackedInput/StackedInput";

import { componentIds } from "views/Forms/stackedInput/StackedInputHelper";
import { InputState } from "context/input-context";
import { BenchmarkInput } from "views/Forms/benckmarkInput/BenchmarkInput";
import WeeklyInputRouter from "views/Forms/weeklyInputRouter";
import WeekElevateBackfill from "views/Forms/weeklyElevateInput/WeeklyElevateBackfill";
import Maintenance from "views/Forms/Maintenance";

export const MaintenanceInfo: Readonly<{
  under_maintenance: boolean;
  until: string;
}> = {
  under_maintenance: false,
  until: "2022-05-30T06:00:00.000Z",
};

function dashRoutes(inputStatus: InputState) {
  if (MaintenanceInfo.under_maintenance) {
    return [
      {
        path: "/inbox",
        name: "Inbox",
        mini: "D",
        component: Maintenance,
        layout: "/admin",
      },
    ];
  }
  const totalInStack = inputStatus.stack
    .map((s) => s.incomplete)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const getIncompleteSize = (id: string) => {
    const entry = inputStatus.stack.find((x) => x.id === id);
    if (!entry) {
      return 0;
    }
    return entry.incomplete;
  };
  return [
    {
      path: "/inbox",
      name: "Inbox",
      mini: "I",
      component: StackedInput,
      layout: "/admin",
      badge: totalInStack,
    },
    {
      path: "/reports",
      name: "Reports",
      mini: "R",
      collapse: true,
      state: "reportsCollapse",
      views: [
        {
          path: "/weekly",
          name: "Weekly",
          mini: "W",
          component: WeeklyInputRouter,
          layout: "/admin",
          badge: getIncompleteSize(componentIds.WEEKLY),
        },
        {
          id: componentIds.QUARTERLY_SOURCING,
          path: "/sourcing",
          name: "Quarterly Sourcing",
          mini: "S",
          component: QuarterlySourcingInput,
          layout: "/admin",
          badge: getIncompleteSize(componentIds.QUARTERLY_SOURCING),
        },
      ],
    },
    {
      path: "/elevate",
      name: "Elevate",
      mini: "E",
      collapse: true,
      state: "elevateCollapse",
      views: [
        {
          path: "benchmark",
          name: "Benchmark Input",
          mini: "B",
          component: BenchmarkInput,
          layout: "/admin",
        },
        {
          path: "weeklyBackfill",
          name: "Weekly Backfill",
          mini: "WB",
          component: WeekElevateBackfill,
          layout: "/admin",
        },
      ],
    },
  ];
}

export { dashRoutes };
