import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import React, { useState, useEffect } from "react";
import app from "firebaseConfig";
import { getFirestore } from "firebase/firestore";
import Button from "components/CustomButtons/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "context/snackbar-context";
import CustomInput from "components/CustomInput/CustomInput";
import CustomAutocomplete from "components/CustomAutocomplete/CustomAutocomplete";
import { Spinner } from "components/Spinner/Spinner";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useAuth, preventSubmitIfUserView } from "context/auth-context";
import {
  deleteFromFirestore,
  readFromFirestore,
  writeToFirestore,
} from "./firestore";
import AddedResultTable from "./Table";
import { TableItemsReducer, TableActionType } from "./reducer";
import SingleSelect from "./SingleSelect";
import { fetchCompanyCache } from "../commonHelpers";
import { DatePicker } from "./DatePicker";
import {
  formatDateAsString,
  formatDateYYYY_MM_DD,
  formatYYYY_MM_DDAsInteger,
} from "./dateHelper";

type CompanyInfo = {
  assetName: string;
  fund: string;
  companyId: string;
};

type PortfolioCompanyInfo = {
  assetName: string;
  fund: string;
  companyId: string | null;
  opportunityId: string;
};

export enum InputType {
  FIXED,
  CUSTOM,
  CUSTOM_PERCENTAGE,
}
export type range = {
  min: number;
  max: number;
  step: number;
  inputType: InputType;
  inputHeader: string;
  description: string;
  isMandatory: boolean;
};
export const metricInfo: Map<string, Map<string, range>> = new Map([
  [
    "TrustPilot",
    new Map([
      [
        "Trustpilot_score",
        {
          isMandatory: true,
          min: 1,
          max: 5,
          step: 0.1,
          inputType: InputType.FIXED,
          description: "",
          inputHeader: "Trustpilot Score",
        },
      ],
    ]),
  ],
  [
    "eNPS",
    new Map([
      [
        "eNPS_score",
        {
          isMandatory: true,
          min: -100,
          max: 100,
          step: 1,
          inputType: InputType.CUSTOM,
          description: "",
          inputHeader: "eNPS Score",
        },
      ],
      [
        "eNPS_completionRate",
        {
          isMandatory: false,
          min: 0,
          max: 100,
          step: 1,
          inputType: InputType.CUSTOM_PERCENTAGE,
          description: "",
          inputHeader: "ENPs Completion Rate",
        },
      ],
    ]),
  ],
  [
    "Data Maturity Score",
    new Map([
      [
        "DataOrganization_score",
        {
          isMandatory: true,
          min: 0,
          max: 4,
          step: 0.1,
          inputType: InputType.CUSTOM,
          description: "",
          inputHeader: "Data Organization",
        },
      ],
      [
        "DataInfrastructure_score",
        {
          isMandatory: true,
          min: 0,
          max: 4,
          step: 0.1,
          inputType: InputType.CUSTOM,
          description: "",
          inputHeader: "Data Infrastructure",
        },
      ],
      [
        "DataManagement_score",
        {
          isMandatory: true,
          min: 0,
          max: 4,
          step: 0.1,
          inputType: InputType.CUSTOM,
          description: "",
          inputHeader: "Data Management",
        },
      ],
      [
        "DataAnalytics_score",
        {
          isMandatory: true,
          min: 0,
          max: 4,
          step: 0.1,
          inputType: InputType.CUSTOM,
          description: "",
          inputHeader: "Data Analytics",
        },
      ],
      [
        "DataGovernance_score",
        {
          isMandatory: true,
          min: 0,
          max: 4,
          step: 0.1,
          inputType: InputType.CUSTOM,
          description: "",
          inputHeader: "Data Governance",
        },
      ],
    ]),
  ],
]);
export type MetricValue = {
  date: number;
  metricName: string;
  companyId: string;
  updatedAt?: string;
  state: "NEW" | "UPDATED" | "READ_FROM_FIRESTORE";
};
type eNPS = MetricValue & {
  eNPS_score: number;
  eNPS_completionRate: number;
};
type TrustPilot = MetricValue & {
  Trustpilot_score: number;
};
const defaultDate = "2022-01-01";
export type Validity = "YES" | "NO" | "NOT_SET";
function BenchmarkInput() {
  const auth = useAuth();
  const db = getFirestore(app);
  const [loadingMsg, setLoadingMsg] = useState("Loading..");
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState<CompanyInfo[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<CompanyInfo | null>(
    null
  );
  const [isSelectedCompanyValid, setSelectedCompanyValid] =
    useState<Validity>("NOT_SET");
  const [autocompleteInputValue, setAutocompleteInputValue] =
    useState<string>("");
  const [selectedMetric, setSelectedMetric] = React.useState<string>("");
  const [submetrics, setSubmetrics] = React.useState<string[]>([]);
  const [formValue, setFormValue] = React.useState<any>(null);
  const [tableValues, dispatchTableValue] = React.useReducer(
    TableItemsReducer,
    []
  );
  const [openFormInput, setOpenFormInput] = React.useState(false);
  const [isOpenConfirmation, setOpenConfirmation] = useState(false);
  const [isOpenOverrideConfirmation, setOpenOverrideConfirmation] =
    useState(false);
  const [isOpenDelelteConfirmation, setOpenDeleteConfirmation] =
    useState(false);
  const [dateToUpdate, setDateToUpdate] = useState(-1);
  const [isUpdating, setUpdating] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<MetricValue | null>(null);

  const note = useSnackbar();
  useEffect(() => {
    async function fetch() {
      setLoadingMsg("Loading companies...");
      setLoading(true);
      try {
        const buildingCompanies: CompanyInfo[] = await fetchCompanyCache(
          db,
          "company_reporting_choices"
        );
        const sourcingCompanies: PortfolioCompanyInfo[] =
          await fetchCompanyCache(db, "ip_sourcing_choices");

        const filteredSourcingCompanies: CompanyInfo[] = sourcingCompanies
          .filter((c) => c.companyId == null) // companyId is set only if a company is part of a portfolio
          .map((c: PortfolioCompanyInfo) => {
            // copy opportunityId into companyId
            return {
              companyId: c.opportunityId,
              fund: c.fund,
              assetName: c.assetName,
            };
          });

        setCompanyList([...buildingCompanies, ...filteredSourcingCompanies]);
      } catch (error) {
        note.sendNotification("Unable to fetch companies", "error");
      }
      setLoading(false);
    }
    fetch();
  }, [db]);

  async function resetFormValues(metric: string) {
    const entry = metricInfo.get(metric);
    let submetrics: string[] = [];
    if (entry != null) {
      submetrics = Array.from(entry.keys());
    }
    setSubmetrics(["date", ...submetrics]);
    const initForm: any = { date: { value: defaultDate, isValid: "YES" } };
    submetrics.forEach((s) => {
      initForm[s] = { value: null, isValid: "NOT_SET" };
    });
    setFormValue(initForm);
  }

  async function selectMetric(metric: string) {
    const company = selectedCompany;
    if (company != null) {
      refreshTable(company, metric);
    }
    setSelectedMetric(metric);
    resetFormValues(metric);
  }

  async function submit(overrideOldValue = false) {
    const invalidMetrics = submetrics.filter(
      (s) => formValue[s].isValid === "NO"
    );
    const inputNotSet = submetrics.filter(
      (s) =>
        formValue[s].isValid === "NOT_SET" && formValue[s].isMandatory === true
    );
    if (invalidMetrics.length === 0 && inputNotSet.length === 0) {
      const data: any = {};
      submetrics.forEach((s) => {
        data[s] =
          s === "date"
            ? formatYYYY_MM_DDAsInteger(formValue[s].value)
            : formValue[s].value;
      });
      data.companyId = selectedCompany?.companyId;
      data.metricName = selectedMetric;
      try {
        if (
          overrideOldValue === false &&
          tableValues.find((v) => v.date === data.date) != null
        ) {
          if (!isUpdating || (isUpdating && dateToUpdate != data.date)) {
            setOpenOverrideConfirmation(true);
            return;
          }
        }
        preventSubmitIfUserView();
        setSubmitting(true);
        await writeToFirestore(db, {
          ...data,
          last_edit_user_id: auth.user.email,
        });
        if (isUpdating && dateToUpdate !== data.date) {
          // the update has created a new document.
          // we also need to remove the old entry
          const toDelete = tableValues.find((v) => v.date === dateToUpdate);
          if (toDelete != null) {
            await deleteFromFirestore(db, toDelete);
          }
        }
        if (isUpdating) {
          dispatchTableValue({
            type: TableActionType.UPDATE_ENTRY,
            payload: {
              date: dateToUpdate,
              entry: { ...data, state: "UPDATED" },
            },
          });
        } else {
          dispatchTableValue({
            type: TableActionType.ADD_ENTRY,
            payload: { ...data, state: "NEW" },
          });
        }
        resetFormValues(selectedMetric);
        setUpdating(false);
        setOpenFormInput(false);
      } catch (error) {
        console.error(error);
        note.sendNotification(
          "Error writing to firestore. Please retry submitting",
          "error"
        );
      } finally {
        setSubmitting(false);
      }
    } else {
      let invalidMsg = "";
      if (invalidMetrics.length > 0) {
        invalidMsg = ` Invalid input: ${invalidMetrics.join(" ")}`;
      }
      if (inputNotSet.length > 0) {
        invalidMsg += ` Input not set: ${inputNotSet.join(" ")}`;
      }
      note.sendNotification(
        `One or more inputs are not correctly set.${invalidMsg}`,
        "error"
      );
    }
  }
  async function deleteRow(data: MetricValue) {
    setOpenDeleteConfirmation(true);
    setRowToDelete(data);
  }
  async function updateRow(data: MetricValue) {
    setDateToUpdate(data.date);
    setUpdating(true);
    const valueToUpdate: any = tableValues.find((v) => v.date === data.date);
    if (valueToUpdate == null) return;
    const formValueWithOldValue: any = {};
    // we always validate before inserting a record
    submetrics.map((s) => {
      formValueWithOldValue[s] = {
        value:
          s === "date"
            ? formatDateYYYY_MM_DD(valueToUpdate[s])
            : valueToUpdate[s],
        isValid: "YES",
      };
    });
    setFormValue(formValueWithOldValue);
    setOpenFormInput(true);
  }

  async function refreshTable(company: CompanyInfo, metric: string) {
    setLoading(true);
    setLoadingMsg(`Loading previous ${metric} input for ${company.assetName}`);
    try {
      const cachedValues = await readFromFirestore(
        db,
        company.companyId,
        metric
      );
      dispatchTableValue({
        type: TableActionType.INITIALIZE,
        payload: cachedValues.map((c: any) => {
          return { ...c, state: "READ_FROM_FIRESTORE" };
        }),
      });
    } catch (error) {
      console.error(error);
      note.sendNotification(
        `Unable to read ${metric} for ${company?.assetName} from firestore cache`,
        "error"
      );
    }
    setLoading(false);
  }

  function getSubMetricSelect(idx: number, metric: string) {
    const oldFormValue: any = JSON.parse(JSON.stringify(formValue));
    function formValueSetter(value: any, isValid: Validity) {
      setFormValue((v: any) => {
        return { ...v, [metric]: { value, isValid } };
      });
    }
    function getDescription(r: range) {
      const value = `${r.description} Please provide a value between ${r.min} and ${r.max} with granularity of ${r.step}.`;
      return (
        <Tooltip title={value}>
          <HelpOutlineIcon />
        </Tooltip>
      );
    }
    if (metric === "date") {
      const minDate = 20000101;
      const maxDate = 20301231;
      const initDate =
        oldFormValue?.date?.value == null
          ? defaultDate
          : oldFormValue.date.value;
      return (
        <Grid container>
          <Grid item xs={10}>
            <DatePicker
              value={initDate}
              isValid={formValue.date.isValid}
              onChange={(value) => {
                let isValid: Validity = "YES";
                if (value == null || value === "") {
                  isValid = "NOT_SET";
                } else {
                  const formatted = formatYYYY_MM_DDAsInteger(value);
                  if (
                    formatted == null ||
                    formatted < minDate ||
                    formatted > maxDate
                  ) {
                    isValid = "NO";
                  }
                }
                formValueSetter(value, isValid);
              }}
            />
          </Grid>
          {formValue.date.isValid === "NO" ? (
            <Grid item xs={1}>
              <Tooltip
                title={`Please provide the date of the metric in the range ${formatDateAsString(
                  minDate
                )} and ${formatDateAsString(maxDate)}`}
              >
                <HelpOutlineIcon />
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      );
    }
    const range = metricInfo.get(selectedMetric)?.get(metric);
    if (range == null) return;
    if (range.inputType == InputType.FIXED) {
      const options = [];
      for (let i = range.min; i <= range.max; i += range.step) {
        options.push(Math.round(i * 100) / 100);
      }
      const initValue =
        oldFormValue?.[metric]?.value == null
          ? null
          : oldFormValue[metric].value;
      return (
        <Grid container>
          <Grid item xs={10}>
            <SingleSelect
              value={initValue}
              title={range.inputHeader}
              data={options}
              callbackOnSelect={(value) => formValueSetter(value, "YES")}
              key={`${metric}-${idx}`}
            />
          </Grid>
          {/* No description as this input type cannot take an invalid value */}
        </Grid>
      );
    }
    if (
      range.inputType == InputType.CUSTOM ||
      range.inputType == InputType.CUSTOM_PERCENTAGE
    ) {
      const initValue =
        oldFormValue?.[metric]?.value == null ? "" : oldFormValue[metric].value;
      const adornment =
        range.inputType === InputType.CUSTOM_PERCENTAGE ? (
          <InputAdornment position="end">%</InputAdornment>
        ) : undefined;
      return (
        <Grid container>
          <Grid item xs={10}>
            <CustomInput
              success={formValue[metric].isValid === "YES"}
              labelText={range.inputHeader}
              error={formValue[metric].isValid === "NO"}
              id="metric"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                endAdornment: adornment,
                value: initValue,
                onChange: (event: any) => {
                  const { value } = event.target;
                  let isValid: Validity = "NO";
                  // % operator doesn't work on floats.
                  // so, we're converting to int first, works for
                  // precision up to 1 decimal place only
                  if (value === "") {
                    isValid = "NOT_SET";
                  } else {
                    const intStep = range.step * 10;
                    const intValue = value * 10;
                    if (
                      value >= range.min &&
                      value <= range.max &&
                      intValue % intStep === 0
                    ) {
                      isValid = "YES";
                    }
                  }
                  formValueSetter(value, isValid);
                },
              }}
              numberProps={{
                step: range.step,
                min: range.min,
              }}
              key={`${metric}-${idx}`}
            />
          </Grid>
          {formValue[metric].isValid === "NO" ? (
            <Grid item xs={1}>
              {getDescription(range)}
            </Grid>
          ) : null}
        </Grid>
      );
    }
    return null;
  }
  function getHeaderMapping() {
    const info = metricInfo.get(selectedMetric);
    const map: Map<string, string> = new Map();
    info?.forEach((value, key) => {
      if (value.inputType === InputType.CUSTOM_PERCENTAGE) {
        map.set(key, `${value.inputHeader} %`);
      } else {
        map.set(key, value.inputHeader);
      }
    });
    return map;
  }
  return (
    <div>
      <Card>
        <CardHeader>
          <h3 style={{ textAlign: "center" }}>Benchmark Input</h3>
        </CardHeader>
        <CardBody>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={5} md={4} lg={3} key={0}>
              <CustomAutocomplete
                labelText={<span>Company Name</span>}
                success={isSelectedCompanyValid === "YES"}
                error={isSelectedCompanyValid === "NO"}
                inputProps={{
                  loading: companyList.length === 0,
                  value: selectedCompany,
                  onChange: (e: any, f: CompanyInfo) => {
                    setSelectedCompany(f);
                    if (f == null) {
                      setSelectedCompanyValid("NO");
                    } else {
                      setSelectedCompanyValid("YES");
                      const metric = selectedMetric;
                      if (metric === "") {
                      } else {
                        refreshTable(f, metric);
                      }
                    }
                  },
                  inputValue: autocompleteInputValue,
                  onInputChange: (e: any, newInputValue: string) => {
                    setAutocompleteInputValue(newInputValue);
                  },
                  options: companyList,
                  groupBy: (option: CompanyInfo) => option.fund,
                  getOptionLabel: (option: CompanyInfo) => option.assetName,
                }}
                formControlProps={{
                  fullWidth: true,
                }}
                id="assetName"
              />
            </Grid>
            <Grid item xs={5} md={4} xl={3} key={1}>
              <SingleSelect
                title="Metric Category"
                data={Array.from(metricInfo.keys())}
                callbackOnSelect={selectMetric}
                value={selectedMetric}
              />
            </Grid>
            <Grid item xs={1} key={2}>
              <Button
                color="primary"
                onClick={() => {
                  setOpenFormInput(true);
                }}
                disabled={selectedCompany === null || selectedMetric === ""}
              >
                ADD
              </Button>
            </Grid>
          </Grid>
          <Dialog
            fullScreen={false}
            disableEscapeKeyDown
            open={openFormInput}
            onClose={() => {
              setOpenFormInput(false);
            }}
            aria-labelledby="benchmark-input"
            scroll="body"
            maxWidth="md"
          >
            <DialogTitle id="input-company">
              <Typography variant="h3" key={0}>
                {selectedCompany?.assetName}
              </Typography>
              <Typography variant="h4" key={1}>
                {selectedMetric}
              </Typography>
            </DialogTitle>

            <DialogContent>
              {submetrics.map((metric, idx) => {
                return (
                  <Grid item xs={10} key={idx}>
                    {getSubMetricSelect(idx, metric)}
                  </Grid>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirmation(true)} key={0}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  submit();
                }}
                key={1}
                disabled={submitting}
              >
                {submitting ? "Submitting ..." : "Submit"}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            aria-labelledby="confirm-cancellation"
            open={isOpenConfirmation}
            onClose={() => setOpenConfirmation(false)}
          >
            <DialogTitle>New input will be lost</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Current input you made will be lost. Continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirmation(false)} key={0}>
                No
              </Button>
              <Button
                onClick={() => {
                  setOpenConfirmation(false);
                  setOpenFormInput(false);
                  setUpdating(false);
                }}
                key={1}
                color="danger"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            aria-labelledby="confirm-override"
            open={isOpenOverrideConfirmation}
            onClose={() => setOpenOverrideConfirmation(false)}
          >
            <DialogTitle> Previous input already exists </DialogTitle>
            <DialogContent>
              <DialogContentText>
                There is already a {selectedMetric} input for the date{" "}
                {formValue?.date?.value}. Would you like to override it?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenOverrideConfirmation(false)}
                key={0}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  setOpenOverrideConfirmation(false);
                  submit(true);
                }}
                key={1}
                color="danger"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            aria-labelledby="confirm-delete"
            open={isOpenDelelteConfirmation}
            onClose={() => setOpenDeleteConfirmation(false)}
          >
            <DialogTitle> Are you sure? </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure that you would like to permanently delete{" "}
                {selectedMetric} input for the date {formValue?.date?.value} ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeleteConfirmation(false)} key={0}>
                No
              </Button>
              <Button
                onClick={async () => {
                  setOpenDeleteConfirmation(false);
                  try {
                    const toDelete = rowToDelete;
                    if (toDelete != null) {
                      await deleteFromFirestore(db, toDelete);
                      dispatchTableValue({
                        type: TableActionType.REMOVE_ENTRY,
                        payload: toDelete.date,
                      });
                    }
                  } catch (error) {
                    console.error(error);
                    note.sendNotification(
                      "Could not delete the entry. Please retry deleting!",
                      "error"
                    );
                  }
                }}
                key={1}
                color="danger"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          {loading ? (
            <Spinner size="medium" position="component" label={loadingMsg} />
          ) : tableValues.length > 0 ? (
            <AddedResultTable
              header={getHeaderMapping()}
              data={tableValues}
              callbackOnDelete={deleteRow}
              callbackOnUpdate={updateRow}
            />
          ) : selectedCompany != null && selectedMetric !== "" ? (
            <Typography align="center">{`No previous ${selectedMetric} input found for ${selectedCompany.assetName}`}</Typography>
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
}

export { BenchmarkInput };
