/* eslint-disable */
import React from "react";
import cx from "classnames";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "context/snackbar-context.js";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

// routing
import { dashRoutes } from "routes.ts";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import { useInputContext } from "context/input-context";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { state, dispatch } = useInputContext();
  const routes = dashRoutes(state);
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [activePathName, setActivePathName] = React.useState("");
  const [image, setImage] = React.useState(require("assets/img/sidebar-1.jpg"));

  // For some reason the primary color is called purple...
  const [color, setColor] = React.useState("purple");
  const [bgColor, setBgColor] = React.useState("white");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const note = useSnackbar();

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  // ref for main panel div
  const mainPanel = React.createRef();
  
  // The component need a way to recognize the route changes to be able to re-render
  // so here I use useLocation hook and save the changing value of activeRoute in a state
  // for <AdminNavBar/>
  const Location = useLocation();
  React.useEffect(() => {
    setActivePathName(getActiveRoute(routes));
  },[Location])

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Verdane Ralloc";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            element={<prop.component/>}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  return (
    <>
      {note.initializeNotification()}
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={"Verdane Ralloc"}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          location={location}
          {...rest}
        />
        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={activePathName}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          <div className={classes.content}>
            <div className={classes.container}>
            <Routes>
                {getRoutes(routes)}
                <Route path="/admin" element={ <Navigate to="/admin/inbox" replace /> } />
                <Route path="/analytics.txt"/>
                <Route path="/*" element={ <Navigate to="/admin/inbox" replace /> } />
            </Routes>
            </div>
          </div>

          <Footer fluid />
        </div>
      </div>
    </>
  );
}
