import React from "react";

// @material-ui/core components
import { makeStyles } from "@mui/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/badgeStyle.js";

const useStyles = makeStyles(styles as any);

type BadgeProps = {
  color:
    | "primary"
    | "warning"
    | "danger"
    | "success"
    | "info"
    | "rose"
    | "gray";
  children: React.ReactNode;
};

export default function Badge(props: any) {
  const { color, children } = props;
  const classes: any = useStyles();
  return (
    <span className={`${classes.badge} ${classes[color]}`}>{children}</span>
  );
}
