import React from "react";

import style from "assets/jss/material-dashboard-pro-react/components/dayInput.js";
import { makeStyles } from "@mui/styles";
import {
  SteppedReducerAction,
  WeeklyComponentDayOption,
} from "../steppedInput/SteppedComponentHelper";
import {
  BuildSourceRecord,
  ElevateWeeklyFirestoreState,
  Project,
  WeeklyElevateComponentState,
} from "./types";
import {
  createBadge,
  daysControl,
  getRowColor,
} from "../steppedInput/weeklyTablesHelpers";

const useStyles = makeStyles(style as any);

export function getTableRowsBuildSource(
  reducerState: WeeklyElevateComponentState,
  projects: Map<string, Project>,
  dispatchFun: any
) {
  if (
    reducerState.selection === null ||
    reducerState.selection === [] ||
    reducerState.selection.length === 0
  ) {
    return [];
  }
  const assetData = reducerState.selection.map(
    (c: BuildSourceRecord, index: number) => {
      const container: any = {};
      container.color = getRowColor(c.days);
      const proj = projects.get(c.projectId);

      container.data = [
        c.assetName,
        proj ? proj.name : "",
        c.invoiceable ? "Yes" : "No",
        // For, build & source, projectId is the identifier as there can be multiple projects for the same company
        daysControl(c.projectId, c.days, dispatchFun, 0.25),
        createBadge(index, c.days),
      ];
      return container;
    }
  );
  return assetData;
}

export function getTableRowsAdmin(
  reducerState: any,
  projects: Map<string, Project>,
  dispatchFun: any
) {
  if (
    reducerState.selection === null ||
    reducerState.selection === [] ||
    reducerState.selection.length === 0
  ) {
    return [];
  }
  const assetData = reducerState.selection.map(
    (c: WeeklyComponentDayOption, index: number) => {
      const container: any = {};
      container.color = getRowColor(c.days);
      container.data = [
        c.assetName,
        "",
        "",
        daysControl(c.id, c.days, dispatchFun, 0.25),
        createBadge(index, c.days),
      ];
      return container;
    }
  );
  return assetData;
}

export function setFetchedOptions(
  sourceOptions: WeeklyComponentDayOption[],
  buildOptions: WeeklyComponentDayOption[],
  adminOptions: WeeklyComponentDayOption[],
  dispatchSourceCompany: (action: SteppedReducerAction) => void,
  dispatchBuildCompany: (action: SteppedReducerAction) => void,
  dispatchAdminCompany: (action: SteppedReducerAction) => void
) {
  dispatchSourceCompany({
    type: "SET_FETCHED_OPTIONS",
    payload: { fetchedOptions: sourceOptions },
  });

  dispatchBuildCompany({
    type: "SET_FETCHED_OPTIONS",
    payload: { fetchedOptions: buildOptions },
  });

  dispatchAdminCompany({
    type: "SET_FETCHED_OPTIONS",
    payload: { fetchedOptions: adminOptions },
  });
}

/**
 * This function does a conversion from "hours" to "days" in the weekly elevate
 * firestore object such that the information is preserved from old firestore
 * model with "hours" as time booking to the new one with "days" as time booking.
 *
 * It is unsafe to operate with the older model with "hours" and the older objects
 * MUST be transformed using this function before using it during backfill.
 *
 * This function is a no-op in case the model is already transformed once or if the
 * firestore object is created using the new model which uses "days".
 *
 */
export function convertHoursToDays(
  record: ElevateWeeklyFirestoreState
): ElevateWeeklyFirestoreState {
  const convert = (x: any) => {
    if (x.days === undefined && x.hours !== undefined) {
      const days = Math.round((x.hours / 8) * 4) / 4;
      return {
        ...x,
        days,
      };
    }
    return x;
  };
  const sourcing = record.sourcing.map((option) => convert(option));
  const building = record.building.map((option) => convert(option));
  const admin = record.admin.map((option) => convert(option));
  return {
    ...record,
    sourcing,
    building,
    admin,
  };
}
