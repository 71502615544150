import React from "react";
// @material-ui/core components
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

 
const styles = {
  grid: {
    margin: "0px",
    padding: "0 15px !important",
  },
};

const useStyles = makeStyles(styles);

interface GridItemProps {
  className: string;
  children: React.ReactNode;
  justifyContent: string;
  xs: number;
  sm: number;
}

export default function GridItem(props: any) {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Grid item {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </Grid>
  );
}
