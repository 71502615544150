import { MetricValue } from "./BenchmarkInput";

type TableValues = MetricValue[];

export enum TableActionType {
  INITIALIZE,
  ADD_ENTRY,
  REMOVE_ENTRY,
  UPDATE_ENTRY,
}
export type TableAction = {
  type: TableActionType;
  payload: any;
};
export function TableItemsReducer(
  state: TableValues,
  action: TableAction
): TableValues {
  switch (action.type) {
    case TableActionType.INITIALIZE: {
      return sortByDate(action.payload);
    }
    case TableActionType.ADD_ENTRY: {
      const oldEntry = state.find((d) => d.date === action.payload.date);
      if (oldEntry == null) {
        return sortByDate([...state, action.payload]);
      } else {
        return sortByDate(
          state.map((s) => {
            if (s.date === action.payload.date) {
              return action.payload;
            }
            return s;
          })
        );
      }
    }
    case TableActionType.REMOVE_ENTRY: {
      const date = action.payload;
      return state.filter((s) => s.date !== date);
    }
    case TableActionType.UPDATE_ENTRY: {
      const { entry, date } = action.payload;
      const newState = state
        .map((s) => {
          if (s.date === date) {
            return entry;
          } else {
            return s;
          }
        })
        .filter((s) => {
          //if we did a override, also remove the duplicate entry with same date.
          if (s !== entry && s.date === entry.date) {
            return false;
          }
          return true;
        });

      return sortByDate(newState);
    }
    default:
      return state;
  }
}

function sortByDate(values: any) {
  const sortedDate: number[] = values
    .map((v: any) => v.date)
    .sort()
    .reverse();
  return sortedDate.map((s) => values.find((v: any) => v.date === s));
}
