import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter.js";
import { useAuth } from "context/auth-context.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const auth = useAuth();
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [isLoading, setLoading] = useState(false);
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justifyCenter="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Verdane Ralloc</h4>
              </CardHeader>
              <CardBody>
                {auth.user ? (
                  <p>Logged in as ({auth.user.email}).</p>
                ) : (
                  <div>
                    <h3>Login to continue</h3>
                  </div>
                )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color="primary"
                  size="lg"
                  block
                  disabled={!!isLoading}
                  onClick={() => {
                    if (auth.user) {
                      auth.signout();
                    } else {
                      setLoading(true);
                      auth.signin(setLoading);
                    }
                  }}
                >
                  {isLoading ? "Logging you in ..." : "Login"}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
