import React from "react";
import { AuthProvider } from "context/auth-context.js";
import { SnackbarProvider } from "context/snackbar-context.js";

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <SnackbarProvider>{children}</SnackbarProvider>
    </AuthProvider>
  );
}

export default AppProviders;
