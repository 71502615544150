import { makeStyles } from "@mui/styles";
import { FormControl, InputLabel } from "@mui/material";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import React from "react";

const useStyles = makeStyles(styles as any);

type Props = {
  labelText: string;
  control: React.ReactElement<any, any>;
};

export default function StyledFormControlLabel({ labelText, control }: Props) {
  const classes = useStyles();

  return (
    <FormControl variant="standard" className={classes.formControl} fullWidth>
      <InputLabel
        className={classes.labelRootCheckbox}
        htmlFor="checkbox"
        shrink
      >
        {labelText}
      </InputLabel>
      {control}
    </FormControl>
  );
}
