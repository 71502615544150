// Code adopted from https://usehooks.com/useAuth/ and
// https://kentcdodds.com/blog/authentication-in-react-applications
// Refactored to work with firebase sdk v9.
//

import React, { useState, useEffect, useContext, createContext } from "react";
import { Spinner } from "components/Spinner/Spinner";
import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import app from "../firebaseConfig.js";

export const isUserView = process.env.REACT_APP_DEV_USER_VIEW === "TRUE";

export function preventSubmitIfUserView() {
  if (isUserView) {
    alert("Cannot submit in User View.");
    throw new Error("Cannot submit in User View.");
  }
}

const authContext = createContext();

// Hook for child functional components to get the auth object
// and re-render when it changes.
const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  // null indicates that there is no info yet about user state
  const [user, setUser] = useState(null);

  const setUserView = (userInfo) => {
    if (isUserView) {
      const userViewFor = process.env.REACT_APP_DEV_USER;
      if (userViewFor !== undefined) {
        userInfo.displayName = `${userViewFor}`;
        userInfo.email = userViewFor;
      } else {
        alert(
          `Cannot load user view. Please set REACT_APP_DEV_USER=useremail in .env file.`
        );
      }
    }
    setUser(userInfo);
  };

  // Wrap any Firebase methods we want to use making sure
  // to save the user to state.
  const signin = (setLoading) => {
    const microsoftProvider = new OAuthProvider("microsoft.com");
    // const microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");
    microsoftProvider.setCustomParameters({
      tenant: "8f5f118a-f56c-487a-a509-8b9c1260ed78", // Allow only users from a particular Azure AD tenant
    });

    const auth = getAuth();
    return signInWithPopup(auth, microsoftProvider)
      .then((response) => {
        setLoading(false);
        setUserView(response.user);
        return response.user;
      })
      .catch((error) => {
        setLoading(false);
        return error.code;
      });
  };

  const signout = () => {
    const auth = getAuth();
    return signOut(auth).then(() => {
      setUser(false);
    });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any
  // component that utilizes this hook to re-render with the
  // latest auth object.
  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (userInfo) => {
      if (userInfo) {
        setUserView(userInfo);
      } else {
        setUser(false);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
  };
}

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
function AuthProvider(props) {
  const auth = useProvideAuth();

  if (auth.user === null) {
    return (
      <Spinner
        size="large"
        position="fullPage"
        label="You're being authenticated"
      />
    );
  }
  return <authContext.Provider value={auth} {...props} />;
}

// AuthProvider provides access to the auth-context to all child-components
// Functional child-components use the useAuth-hook to access the auth-context
// Class-based child-components use the authContext to access the auth-context
export { AuthProvider, useAuth, authContext };
