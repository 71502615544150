import * as React from "react";
import { doc, getDoc } from "firebase/firestore";
import QuarterlySourcingInput from "../steppedInput/QuarterlySourcingInput";
import { fetchBackendTime } from "../commonHelpers";
import {
  QuarterlySourcingFireStoreState,
  WeeklyFireStoreState,
} from "../steppedInput/SteppedComponentHelper";
import WeeklyInputRouter from "../weeklyInputRouter";

// does a lookup for the document in the firestore collection
async function lookupInCache(
  db: any,
  collectionName: string,
  documentId: string
) {
  const docRef = doc(db, collectionName, documentId);
  return getDoc(docRef).then((docSnap) => {
    if (!docSnap.exists) {
      return null;
    }
    return docSnap.data();
  });
}

type Collection = {
  componentName: string;
  component: JSX.Element;
  name: string;
  collectionName: string;
  idByEmail: boolean;
  cacheTimeField: string;
  backendTime: string;
};

export type UserInfo = {
  user_principal_name: string;
  salesforce_user_id: string;
  reporting_in_ralloc: boolean;
  reporting_companies: string[];
  reporting_companies_names: string[];
  job_title?: string;
  bamboohr_id?: number;
  /** there are other departments as well, but we're not interested in them...
   * @TODO: should other department personnels see IP or Elevate version of dashboard?
   */
  department?: "Investment professionals" | "Elevate";
  thematic?: string;
  cluster_affiliation?: string;
  on_holiday_today: boolean;
};
/**
 * The values here are tied to the collection name in firestore.
 * Do not change them!
 */
const componentIds = Object.freeze({
  WEEKLY: "weekly_input",
  QUARTERLY_SOURCING: "quarterly_sourcing_input",
  BENCHMARK: "benchmark_input",
});

function parseBackendTime(response: any) {
  return {
    week: `${response.weeklyInput.inputYear}W${response.weeklyInput.inputWeek}`,
    month: `${response.monthlyInput.inputYear}M${response.monthlyInput.inputMonth}`,
    quarter: `${response.quarterlyInput.inputYear}Q${response.quarterlyInput.inputQuarter}`,
    year: response.yearlyInput.inputYear,
  };
}

const InputCollection = (
  isElevater: boolean,
  timeResponse: any
): Collection[] => {
  const time = parseBackendTime(timeResponse);
  return [
    {
      componentName: "Weekly Report",
      component: <WeeklyInputRouter isStackedView />,
      name: componentIds.WEEKLY,
      collectionName: isElevater ? "weekly_elevate_input" : componentIds.WEEKLY,
      idByEmail: true,
      cacheTimeField: "week_for",
      backendTime: time.week,
    },
    {
      componentName: "Quarterly Sourcing Report",
      component: <QuarterlySourcingInput isStackedView />,
      name: componentIds.QUARTERLY_SOURCING,
      collectionName: componentIds.QUARTERLY_SOURCING,
      idByEmail: true,
      cacheTimeField: "quarter_for",
      backendTime: time.quarter,
    },
  ];
};

export async function getUserInfo(user: any, db: any): Promise<UserInfo> {
  const collection_prefix = process.env.REACT_APP_FIREBASE_DB_PREFIX as string;
  const userInfo = await lookupInCache(
    db,
    `${collection_prefix}user`,
    user.email
  );
  return userInfo as UserInfo;
}

/**
 * This function returns all the InputForm components + the incomplete form count for each of these components.
 * This count is calculated by checking the value in the firestore cache. Inline comments explain the details about how this
 * is computed for the different component types.
 */
async function getAllInputForms(user: any, db: any, userInfo: UserInfo) {
  const collection_prefix = process.env.REACT_APP_FIREBASE_DB_PREFIX as string;
  const stack = new Array();
  const time = await fetchBackendTime(user);
  const isElevater = userInfo.department === "Elevate";
  const collection = InputCollection(isElevater, time);
  const { email } = user;
  if (userInfo == null) {
    // no user info
    return { stack, userInfo };
  }

  for (const coll of collection) {
    let incomplete = 0;
    if (isElevater && coll.name === componentIds.QUARTERLY_SOURCING) {
      // elevaters dont do sourcing reports
    } else if (coll.idByEmail) {
      /**
       * This block is reached by the components which use email as the document id
       * in the firestore cache. These are the stepped componenents: WeeklyInput and QuarterlySourcingInput
       * These components can be skipped from the stacked view if we find a value in the cache and
       * the reporting period in the cache matches the current reporting period.
       */

      // interns do not need to fill quarterly sourcing
      if (coll.name === componentIds.QUARTERLY_SOURCING) {
        const isIntern = userInfo.job_title
          ?.toLocaleLowerCase()
          .includes("intern");
        const filteredClusters = ["Capital Markets"];
        const inFilteredClusters =
          userInfo.cluster_affiliation == undefined
            ? false
            : filteredClusters.includes(userInfo.cluster_affiliation);
        if (isIntern || inFilteredClusters) continue;
      }
      const documentIdSuffix = `_${coll.backendTime}`;
      const value = await lookupInCache(
        db,
        collection_prefix + coll.collectionName,
        email + documentIdSuffix
      );
      if (value == null || !(value[coll.cacheTimeField] === coll.backendTime)) {
        incomplete = 1; // These are single form components
      } else if (coll.name === componentIds.QUARTERLY_SOURCING) {
        const firestoreState = value as QuarterlySourcingFireStoreState;
        if (firestoreState.sourcingMeeting.length === 0) {
          incomplete = 1;
        }
      } else if (coll.name === componentIds.WEEKLY) {
        const firestoreState = value as WeeklyFireStoreState;
        const inputCount =
          firestoreState.sourcing.length +
          firestoreState.building.length +
          firestoreState.admin.length;
        if (inputCount === 0) {
          incomplete = 1;
        }
      }
    } else {
      // filter out elevaters who dont report in ralloc
      /**
       *  This block is reached by the components which use company id as the document id
       *  in the firestore cache. These are the Card Components: MonthlyInput, QuarterlyInput and YearlyInput
       *  So, we need to do a lookup using all the company ids that a user is supposed to report on.
       *  These components can only be skipped from the stacked view iff we find a value for each of the companyIds
       *  in the cache and the cached value has all the input values filled in and the reporting period in the cache
       *  matches the current reporting period.
       */
      for (const id of userInfo.reporting_companies) {
        const value = await lookupInCache(
          db,
          collection_prefix + coll.name,
          id
        );
        if (
          value == null ||
          !value.isComplete ||
          !(value[coll.cacheTimeField] === coll.backendTime)
        ) {
          incomplete += 1;
        }
      }
    }
    stack.push({
      id: coll.name,
      component: coll.component,
      incomplete,
    });
  }
  return { stack, userInfo };
}

export { getAllInputForms, componentIds };
