import React from "react";

// @material-ui/core components
import { withStyles } from "@mui/styles";
// core components
import GridContainer from "components/Grid/GridContainer";
import DayInput from "components/DayInput/DayInput.js";
import {
  ComponentState,
  GenericComponentDayOption,
  SteppedReducerAction,
} from "../SteppedComponentHelper";

type Props = {
  buildCompany: ComponentState<GenericComponentDayOption>;
  dispatchBuildCompany: React.Dispatch<SteppedReducerAction>;
  tableRows: any;
};

function WeeklyInputStep2({
  buildCompany,
  dispatchBuildCompany,
  tableRows,
}: Props) {
  return (
    <form>
      <GridContainer justifyContent="center">
        <DayInput
          tableRows={tableRows}
          tableHead={["Asset Name", "Actions", "Days"]}
          labelText="Target/Process Name"
          labelUnits="Days"
          inputSteps={0.25}
          companyList={buildCompany}
          dispatchCompanyList={dispatchBuildCompany}
          emptyMessage="Your report is empty."
        />
      </GridContainer>
    </form>
  );
}

export default withStyles({})(WeeklyInputStep2);
