import React from "react";
import { useAuth } from "context/auth-context.js";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import { ProvideInput } from "context/input-context";
import { BrowserRouter } from "react-router-dom";

export default function App() {
  const auth = useAuth();
  return auth.user ? (
    <ProvideInput>
      {/* The BrowserRouter must be in app.js for the useLocation to be able to use in AdminLayout(Admin.js) */}
      <BrowserRouter>
        <AdminLayout />
      </BrowserRouter>
    </ProvideInput>
  ) : (
    <AuthLayout />
  );
}
