import React from "react";

// @material-ui/core components
import { withStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import { SteppedReducerAction } from "views/Forms/steppedInput/SteppedComponentHelper";
import ElevateInput from "../ElevateInput";
import { Project, WeeklyElevateComponentState } from "../types";
import { ProjectListReducerAction } from "../reducer";

type Props = {
  sourceCompany: WeeklyElevateComponentState;
  dispatchSourceCompany: React.Dispatch<SteppedReducerAction>;
  dispatchActiveProjectCache: React.Dispatch<ProjectListReducerAction>;
  checkIfEntryInTableExists: (project: Project) => boolean;
  tableRows: Array<any>;
};

function WeeklyInputStep1({
  sourceCompany,
  dispatchSourceCompany,
  dispatchActiveProjectCache,
  checkIfEntryInTableExists,
  tableRows,
}: Props) {
  return (
    <form>
      <GridContainer justifyContent="center">
        <ElevateInput
          tableRows={tableRows}
          tableHead={[
            "Asset Name",
            "Project Name",
            "Invoiceable",
            "Actions",
            "Days",
          ]}
          labelText="Target/Process Name"
          labelUnits="Days"
          inputSteps={0.25}
          companyList={sourceCompany}
          dispatchCompanyList={dispatchSourceCompany}
          dispatchActiveProjectCache={dispatchActiveProjectCache}
          checkIfEntryInTableExists={checkIfEntryInTableExists}
          categoryOptions={["Pre-DD", "Due Diligence"]}
          emptyMessage="Your report is empty."
          instruction="Log all value adding work projects, even small ones. Follow-on processes should be logged as 'Source & Invest'"
          secondInstruction=""
        />
      </GridContainer>
    </form>
  );
}

export default withStyles({})(WeeklyInputStep1);
