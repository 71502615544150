import React from "react";

// @material-ui/core components
import { withStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import MeetingInput from "components/MeetingInput/MeetingInput.js";

type Props = {
  sourcingMeeting: any;
  dispatchSourcingMeeting: any;
  tableRows: Array<any>;
};

function QuarterlySourcingInputStep({
  sourcingMeeting,
  dispatchSourcingMeeting,
  tableRows,
}: Props) {
  return (
    <form>
      <GridContainer justifyContent="center" spacing={4}>
        <MeetingInput
          tableRows={tableRows}
          tableHead={["Geography", "Type", "Actions", "Meetings"]}
          labelText="Geography"
          labelUnits="Meetings"
          inputSteps={0.5}
          companyList={sourcingMeeting}
          dispatchSourcingMeeting={dispatchSourcingMeeting}
          emptyMessage="Your report is empty."
          instruction="If you participated in a sourcing meeting with a target alone, book 1 meeting. Meetings with targets attended by other Verdaners are to be booked as 0.5 meetings."
          secondInstruction="Only one meeting per target/advisor per quarter can be counted. Select the 'Generic' type in case you don't know the deal type yet."
        />
      </GridContainer>
    </form>
  );
}

export default withStyles({})(QuarterlySourcingInputStep);
