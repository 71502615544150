import { useInputContext } from "context/input-context";
import { func } from "prop-types";
import React from "react";
import WeeklyInput from "./steppedInput/WeeklyInput";
import WeeklyElevateInput from "./weeklyElevateInput/WeeklyElevateInput";

type Props = {
  isStackedView?: boolean;
};

/**
 * Routes to elevate weekly view for users who in "Elevate" department
 * The rest are routed to "IP" view
 */
export default function WeeklyInputRouter(props: Props) {
  const inputContext = useInputContext();
  if (inputContext.state.userInfo?.department === "Elevate") {
    return <WeeklyElevateInput isStackedView={props.isStackedView} />;
  }
  return <WeeklyInput isStackedView={props.isStackedView} />;
}
