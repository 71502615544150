/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base colors for the Material Dashboard 2 PRO React TSUI Dashboard PRO Material.
 * You can add new color using this file.
 * You can customized the colors for the entire Material Dashboard 2 PRO React TSUI Dashboard PRO Material using thie file.
 */

// types
interface GradientsTypes {
  main: string;
  state: string;
}

interface Types {
  white:
    | {
        main: string;
        focus: string;
      }
    | any;
  transparent:
    | {
        main: string;
      }
    | any;
  black:
    | {
        light: string;
        main: string;
        focus: string;
      }
    | any;
  grey:
    | {
        [key in string | number]: string;
      }
    | any;
  gradients:
    | {
        info: GradientsTypes;
      }
    | any;
  coloredShadows:
    | {
        [key: string]: string;
      }
    | any;
}

const colors: Types = {
  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    main: "#000000",
  },

  grey: {
    300: "#dee2e6",
  },

  gradients: {
    info: {
      main: "#071D49",
      state: "#213b5d",
    },
  },

  coloredShadows: {
    info: "#00bbd4",
  },
};

export default colors;
