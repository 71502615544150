import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

// core components
import {
  Grid,
  Checkbox,
  Container,
  createFilterOptions,
  Tooltip,
} from "@mui/material";

// custom components
import CustomInput from "components/CustomInput/CustomInput";
import CustomAutocomplete from "components/CustomAutocomplete/CustomAutocomplete";
import { Spinner } from "components/Spinner/Spinner";
import DayTable from "components/DayTable/DayTable.js";

import selectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import style from "assets/jss/material-dashboard-pro-react/components/dayInput.js";
import ButtonTS from "components/CustomButtons/Button";

// firestore
import app from "firebaseConfig";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { useSnackbar } from "context/snackbar-context";
import { useAuth } from "context/auth-context";
import { v4 as uuidv4 } from "uuid";
import { getAnalytics, logEvent } from "firebase/analytics";
import { createProject, getProjects } from "./firestore";

// Analytics
import CreateProjectDialog from "./CreateProjectDialog";
import StyledFormControlLabel from "./StyledFormControlLabel";
import { CompanyRecord, Project } from "./types";
import { SteppedReducerAction } from "../steppedInput/SteppedComponentHelper";
import { ProjectListReducerAction } from "./reducer";

const useStyles = makeStyles({ ...(style as any), ...(selectStyle as any) });

type Options = {
  isLoading: boolean;
  options: Array<any>;
};

export const emptyProject: Project = {
  assetName: "",
  fund: "",
  companyId: "",
  opportunityId: "",
  _insert_time: null,
  _update_time: null,
  name: "",
  id: "",
  description: "",
  category: "",
  isNew: true,
  createdBy: "",
  createdById: "",
};

type ElevateInputProps = {
  categoryOptions: string[];
  companyList: Options;
  checkIfEntryInTableExists: (project: Project) => boolean;
  dispatchCompanyList: React.Dispatch<SteppedReducerAction>;
  dispatchActiveProjectCache: React.Dispatch<ProjectListReducerAction>;
  emptyMessage: string;
  instruction: string;
  secondInstruction: string;
  labelText: string;
  labelUnits: string;
  inputSteps: number;
  tableRows: Array<any>;
  tableHead: Array<any>;
  children?: React.ReactNode;
};

type ProjectOption = Project & {
  target_name?: string;
};
const filter = createFilterOptions<ProjectOption>();

export default function ElevateInput(props: ElevateInputProps) {
  const {
    categoryOptions,
    companyList,
    dispatchCompanyList,
    dispatchActiveProjectCache,
    checkIfEntryInTableExists,
    emptyMessage,
    instruction,
    secondInstruction,
    labelText,
    labelUnits,
    inputSteps,
    tableRows,
    tableHead,
  } = props;
  const db = getFirestore(app);
  const note = useSnackbar();
  const auth = useAuth();
  const [asset, setAsset] = useState<CompanyRecord | null>(null);
  const [project, setProject] = useState<Project>(emptyProject);
  const [projectValid, setProjectValid] = useState("");
  const [availableProjects, setAvailableProjects] = useState<Project[]>([]);
  const [assetValid, setAssetValid] = useState("");
  const [assetDays, setAssetDays] = useState(0);
  const [invoiceable, setInvoiceable] = useState(false);
  const [assetDaysValid, setAssetDaysValid] = useState("");
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const [autocompleteInputValue2, setAutocompleteInputValue2] = useState("");
  const [openFormInput, setOpenFormInput] = useState(false);
  const classes = useStyles();
  const verifyAsset = (asset: any, fieldName: string) => {
    if (
      asset === null ||
      asset === "" ||
      typeof asset[fieldName] === "undefined"
    ) {
      return false;
    }
    if (asset.assetName.length > 0) {
      return true;
    }
    return false;
  };

  const verifyQuarter = (value: number, inputSteps: number) => {
    // second terms catches (empty) strings
    if (
      value % inputSteps === 0 &&
      !Number.isNaN(value) &&
      value !== 0 &&
      value > 0
    ) {
      return true;
    }
    return false;
  };

  // Add a selected asset with the respective Hours Input to the selection.
  const addAsset = () => {
    if (assetValid === "success") {
      if (projectValid === "success") {
        if (assetDaysValid === "success") {
          const daysInput = assetDays;
          const newItem = {
            ...(asset as any),
            days: daysInput,
            projectId: project.id,
            invoiceable,
          };

          if (checkIfEntryInTableExists(project) === true) {
            note.sendNotification(
              `Entry for project "${project.name}" already exists. Please update the existing entry from the table below or delete it before trying to add.`,
              "error"
            );
            return;
          }
          dispatchActiveProjectCache({
            type: "ADD_PROJECT",
            payload: project,
          });
          dispatchCompanyList({
            type: "ADD_SELECTION",
            payload: { newSelection: newItem },
          });

          // remove added asset from autocomplete option
          setAsset(null); // clear autocomplete selection
          setAssetValid("error");
          setAssetDays(0); // clear days input
          setAssetDaysValid("error");
          setProjectValid("error");
          setProject(emptyProject);
          setAutocompleteInputValue2("");
          setInvoiceable(false);
        } else {
          setAssetDaysValid("error");
        }
      } else {
        setProjectValid("error");
      }
    } else {
      setAssetValid("error");
    }
  };

  // controller that checks validation state before updating inputs.
  const change = async (
    stateName: string,
    newValue: any,
    validationSuccessful: boolean
  ) => {
    if (stateName === "asset") {
      setAsset(newValue);
      if (validationSuccessful === true) {
        setAssetValid("success");
        const projects = await getProjects(db, newValue.companyId);
        setAvailableProjects(projects);
        console.log("the projectss", projects);
        setAutocompleteInputValue2("");
        setProject(emptyProject);
      } else if (validationSuccessful === false) {
        setAssetValid("error");
      } else {
        throw new Error("Invalid validation result.");
      }
    } else if (stateName === "assetDays") {
      setAssetDays(Number.parseFloat(newValue));
      if (validationSuccessful === true) {
        setAssetDaysValid("success");
      } else if (validationSuccessful === false) {
        setAssetDaysValid("error");
      } else {
        throw new Error("Invalid validation result.");
      }
    } else if (stateName === "project") {
      if (validationSuccessful === true) {
        setProject(newValue);
        setProjectValid("success");
      } else {
        setProjectValid("error");
        setProject(emptyProject);
      }
    } else {
      throw new Error("Invalid state to be updated");
    }
  };
  /*
    Create a new project and add it to the available projects.
    returns true if project was created successfully.
  */
  async function createNewProject(newProject: Project): Promise<boolean> {
    const currentAsset = asset;
    if (availableProjects.findIndex((p) => newProject.name === p.name) !== -1) {
      note.sendNotification(
        `Project with name: "${newProject.name}" already exists. Please use a different name.`,
        "error"
      );
      return false;
    }
    if (newProject.name.trim().length === 0) {
      note.sendNotification(`Project name cannot be empty!`, "error");
      return false;
    }
    if (newProject.category.trim().length === 0) {
      note.sendNotification(`Please select a project category first!`, "error");
      return false;
    }
    if (currentAsset != null) {
      console.log(
        `new Project: name:${newProject.name} desc:${newProject.description}`
      );

      const firestoreProject: Project = {
        ...currentAsset,
        _insert_time: serverTimestamp(),
        _update_time: serverTimestamp(),
        name: newProject.name,
        id: uuidv4(),
        description: newProject.description,
        category: newProject.category,
        isNew: true,
        createdBy: auth.user.email,
        createdById: auth.user.uid,
      };
      try {
        await createProject(db, firestoreProject);
        // available projects
        setOpenFormInput(false);
        setProject(firestoreProject);
        setAvailableProjects((p) => {
          return [...p, firestoreProject];
        });
        setProjectValid("success");
        const analytics = getAnalytics(app);
        logEvent(analytics, "Elevate Project Created");
        return true;
      } catch (error) {
        console.error(error);
        note.sendNotification(
          `Could not add a new project. "${newProject.name}". Please retry to create project.`,
          "error"
        );
        return false;
      }
    } else {
      note.sendNotification(
        `Could not add a new project. "${newProject.name}". Please refresh ralloc and retry to create project.`,
        "error"
      );
      return false;
    }
  }

  const compositeLabelText = <span>{labelText}</span>;
  const compositeLabelUnits = <span>{labelUnits}</span>;

  function closeAndCancelFormInput() {
    setProjectValid("error");
    setProject(emptyProject);
    setAutocompleteInputValue2("");
    setInvoiceable(false);
    setOpenFormInput(false);
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item className={classes.dayGridItem} xs={10} lg={3}>
        <CustomAutocomplete
          labelText={compositeLabelText}
          success={assetValid === "success"}
          error={assetValid === "error"}
          inputProps={{
            loading: companyList.isLoading,
            value: asset,
            onChange: (e: any, newValue: any) => {
              change("asset", newValue, verifyAsset(newValue, "assetName"));
            },
            inputValue: autocompleteInputValue,
            onInputChange: (e: any, newInputValue: any) => {
              setAutocompleteInputValue(newInputValue);
            },
            options: companyList.options,
            groupBy: (option: any) => option.fund,
            getOptionLabel: (option: any) =>
              option.assetName ? option.assetName : "",
            renderOption: (params: any, option: any) => {
              // the key must be overridden in order to have unique keys,
              // the params has the key set as assetName using getOptionLabel
              return (
                <li {...params} key={option.id}>
                  {option.assetName}
                </li>
              );
            },
          }}
          formControlProps={{
            fullWidth: true,
          }}
          id="assetName"
        />
      </Grid>

      <Grid item className={classes.dayGridItem} xs={10} lg={3}>
        <CustomAutocomplete
          labelText="Select a project"
          success={projectValid === "success"}
          error={projectValid === "error"}
          inputProps={{
            loading: companyList.isLoading,
            value:
              project.id === ""
                ? null
                : project /* value must exist in the option list */,
            onChange: async (e: any, newValue: ProjectOption) => {
              const currentAsset = asset;
              if (currentAsset == null) {
                return;
              }
              if (
                newValue != null &&
                newValue.target_name != null &&
                verifyAsset(currentAsset, "assetName")
              ) {
                setOpenFormInput(true);
              } else {
                change("project", newValue, verifyAsset(newValue, "name"));
              }
            },
            inputValue: autocompleteInputValue2,
            onInputChange: (e: any, newInputValue: any) => {
              if (e == null || e.type === "blur") {
                // Dont clear input
                return;
              }
              setAutocompleteInputValue2(newInputValue);
            },
            options: availableProjects,
            getOptionLabel: (option: ProjectOption) => {
              const targetName = option.target_name;
              if (targetName != null) {
                return targetName;
              }
              return option.name ? option.name : "";
            },
            isOptionEqualToValue: (option: ProjectOption, value: Project) =>
              option.id === value.id,
            renderOption: (params: any, option: ProjectOption) => {
              const category = option.category ? option.category : "Not Set";
              const desc =
                option.description === "" ? "Empty" : option.description;
              const tooltipText = `Category: ${category} \n Description: ${desc}`;
              const title = (
                <div style={{ whiteSpace: "pre-line" }}>{tooltipText}</div>
              );
              return (
                <Tooltip title={title} {...params}>
                  <Container maxWidth={false}>{option.name}</Container>
                </Tooltip>
              );
            },
            filterOptions: (options: any, params: any) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value, but prevent adding project with same name
              if (
                params.inputValue !== "" &&
                filtered.findIndex((x) => x.name == params.inputValue) == -1
              ) {
                filtered.push({
                  ...emptyProject,
                  name: `Create new project: ${params.inputValue}`,
                  target_name: params.inputValue,
                });
              }

              return filtered;
            },
            disabled: asset == null,
          }}
          formControlProps={{
            fullWidth: true,
          }}
          enterCallback={(targetValue: string) => {
            if (
              availableProjects.findIndex(
                (proj) => proj.name === targetValue
              ) === -1
            ) {
              // open form only when there is no matching existing projects
              setOpenFormInput(true);
            }
          }}
          id="assetName"
        />
      </Grid>

      <Grid item xs={3} lg={1}>
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={invoiceable}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInvoiceable(e.target.checked)
              }
            />
          }
          labelText="Invoiceable"
        />
      </Grid>

      <Grid item xs={5} lg={2}>
        <CustomInput
          success={assetDaysValid === "success"}
          error={assetDaysValid === "error"}
          labelText={compositeLabelUnits}
          id="assetDays"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: "number",
            value: assetDays,
            onChange: (event: any) => {
              change(
                "assetDays",
                event.target.value,
                verifyQuarter(event.target.value, inputSteps)
              );
            },
          }}
          numberProps={{
            step: inputSteps,
            min: 0,
          }}
        />
      </Grid>

      <Grid item className={classes.addGridItem} xs={2} lg={1}>
        <Tooltip title="Add Input" aria-label="add-input">
          <ButtonTS
            color="primary"
            size="sm"
            className={classes.addAssetButtonTS}
            onClick={() => addAsset()}
          >
            Add
          </ButtonTS>
        </Tooltip>
      </Grid>

      <CreateProjectDialog
        openFormInput={openFormInput}
        createNewProject={createNewProject}
        closeFormInput={closeAndCancelFormInput}
        initName={autocompleteInputValue2}
        categoryOptions={categoryOptions}
      />

      {companyList.isLoading ? (
        <Grid item xs={10} sm={10}>
          <Spinner
            size="medium"
            position="component"
            label="Loading past inputs"
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={10}>
          {tableRows.length > 0 ? (
            <DayTable tableRows={tableRows} tableHead={tableHead} />
          ) : (
            <h5>
              {emptyMessage}
              <br />
            </h5>
          )}
          <h5>
            <small>{instruction}</small>
            {secondInstruction ? (
              <>
                <br />
                <small>{secondInstruction}</small>
              </>
            ) : (
              ""
            )}
          </h5>
        </Grid>
      )}
      {props?.children}
    </Grid>
  );
}
