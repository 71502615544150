// Code adopted from https://usehooks.com/useAuth/ and
// https://kentcdodds.com/blog/authentication-in-react-applications

import * as React from "react";
import { getFirestore } from "firebase/firestore";
import app from "../firebaseConfig.js";
import { useAuth } from "./auth-context.js";
import {
  getAllInputForms,
  getUserInfo,
  UserInfo,
} from "../views/Forms/stackedInput/StackedInputHelper";

type State = {
  error: boolean;
  loading: boolean;
  stack: {
    component: JSX.Element;
    id: string;
    incomplete: number;
  }[];
  userInfo: UserInfo | null;
};
type Action = {
  type: string;
  payload: any;
};
// TODO: replace with enum. Currently these values are directly used
// in MonthlyInput and QuarterlyInput which are js
const ActionType = Object.freeze({
  SET_ERROR: "SET_ERROR",
  SET_LOADING: "SET_LOADING",
  INITIALIZE_STACK: "INITIALIZE_STACK",
  INCREMENT_INCOMPLETE_COUNT: "INCREMENT_INCOMPLETE_COUNT",
  DECREMENT_INCOMPLETE_COUNT: "DECREMENT_INCOMPLETE_COUNT",
  INITIALIZE_USER_INFO: "INITIALIZE_USER_INFO",
});
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.INITIALIZE_STACK:
      return {
        ...state,
        stack: action.payload,
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionType.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionType.INCREMENT_INCOMPLETE_COUNT: {
      const entryExists = state.stack.some((e) => e.id === action.payload);
      if (entryExists) {
        return {
          ...state,
          stack: state.stack.map((e) => {
            const newCount =
              e.id === action.payload ? e.incomplete + 1 : e.incomplete;
            return {
              ...e,
              incomplete: newCount,
            };
          }),
        };
      }
      return state;
    }
    case ActionType.DECREMENT_INCOMPLETE_COUNT: {
      const entry = state.stack.find((e) => e.id === action.payload);
      if (entry != undefined) {
        return {
          ...state,
          stack: state.stack.map((e) => {
            const newCount =
              e.id === action.payload && e.incomplete > 0
                ? e.incomplete - 1
                : e.incomplete;
            return {
              ...e,
              incomplete: newCount,
            };
          }),
        };
      }
      return state;
    }
    case ActionType.INITIALIZE_USER_INFO: {
      return {
        ...state,
        userInfo: action.payload,
      };
    }
    default:
      return state;
  }
}

const emptyState = (): State => {
  return { stack: [], loading: true, error: false, userInfo: null };
};
const InputContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: emptyState(),
  dispatch: () => null,
});

// Provider hook that creates input status object and the dispatcher for reducer
function useProvideInputStatus() {
  const db = getFirestore(app);
  const auth = useAuth();
  const [state, dispatchAction] = React.useReducer(reducer, emptyState());

  React.useEffect(() => {
    async function fillUpStack() {
      try {
        const userInfo = await getUserInfo(auth.user, db);
        dispatchAction({
          type: ActionType.INITIALIZE_USER_INFO,
          payload: userInfo,
        });
        const cachedValues = await getAllInputForms(auth.user, db, userInfo);
        dispatchAction({
          type: ActionType.INITIALIZE_STACK,
          payload: cachedValues.stack,
        });
      } catch (e) {
        console.error(JSON.stringify(e));
        dispatchAction({ type: ActionType.SET_ERROR, payload: true });
      } finally {
        dispatchAction({ type: ActionType.SET_LOADING, payload: false });
      }
    }
    fillUpStack();
  }, []);

  return { state, dispatch: dispatchAction };
}

// Hook for child functional components to get the input status object
// and re-render when it changes.
const useInputContext = () => {
  return React.useContext(InputContext);
};

function ProvideInput({ children }: any) {
  const inputStatus = useProvideInputStatus();
  return (
    <InputContext.Provider value={inputStatus}>
      {children}
    </InputContext.Provider>
  );
}

export { useInputContext, ProvideInput };
export type { State as InputState, Action as InputAction };
export { ActionType as InputActionType };
export { InputContext };
