import {
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
  Alert,
} from "@mui/material";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import React, { useEffect, useState } from "react";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";

import { withStyles } from "@mui/styles";

interface Props {
  title: string;
  steps: { component: JSX.Element; stepName: string }[];
  classes: any;
  isSubmitLoading: boolean;
  finishButtonClick: () => void;
  timeInstruction: JSX.Element;

  isPast?: boolean;
  pastReportText?: string;
  finishButtonText?: string;
  initialStep?: number;
}
function DayWizard({
  isPast = false,
  pastReportText = "You're working on a past report.",
  finishButtonText = "Finish",
  initialStep = 0,
  title,
  steps,
  classes,
  isSubmitLoading,
  finishButtonClick,
  timeInstruction,
}: Props) {
  function getStepContent(stepIndex: number) {
    return steps[stepIndex];
  }
  const [activeStep, setActiveStep] = useState<number>(initialStep);
  const isLastStep: boolean = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  useEffect(() => {
    setActiveStep(initialStep);
  }, [initialStep]);

  return (
    <div className={classes.wizardContainer}>
      <Card className={classes.card}>
        <div className={classes.wizardHeader}>
          <h3 className={classes.title}>
            {isPast ? (
              <div className={classes.warningMuiBackGround}>
                {/* warningMuiBackGround is a custom style to bring back alert */}
                <Alert severity="warning">
                  <Typography>{pastReportText}</Typography>
                </Alert>
              </div>
            ) : (
              title
            )}
          </h3>
        </div>
        {steps.length > 1 ? (
          <div className={classes.wizardNavigation}>
            <Stepper nonLinear activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => (
                <Step key={step.stepName}>
                  <StepButton onClick={() => setActiveStep(index)}>
                    <StepLabel>
                      <h4>{step.stepName}</h4>
                    </StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
        ) : null}
        <div className={classes.content}>
          <div className={classes.timeInstruction}>{timeInstruction}</div>
          {getStepContent(activeStep).component}
        </div>
        <div className={classes.footer}>
          <div className={classes.left}>
            <Button onClick={handleBack} disabled={activeStep === 0}>
              Back
            </Button>
          </div>
          <div className={classes.right}>
            {isLastStep ? (
              <Button
                onClick={finishButtonClick}
                color="primary"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? "Sending.." : finishButtonText}
              </Button>
            ) : (
              <Button onClick={handleNext} color="primary">
                Next
              </Button>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

DayWizard.defaultProps = {
  isPast: false,
  pastReportText: "You're working on a past report.",
  finishButtonText: "Finish",
  initialStep: 0,
};

export default withStyles(wizardStyle as any)(DayWizard);
