import { grayColor } from "assets/jss/material-dashboard-pro-react.js";

const DayInput = {
  actionButton: {
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },

  addAssetButton: {
    margin: "30px 0 0 0",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },

  addAssetButtonTS: {
    margin: "35px 0 0 10px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
    fontSize: "0.6875rem",
  },

  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative",
  },

  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: grayColor[3],
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
    transition: "0.3s ease all",
    letterSpacing: "unset",
  },

  right: {
    textAlign: "right",
  },

  addGridItem: {
    padding: "0 16px 0 0 !important",
  },
};

export default DayInput;
