import React, { useReducer, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import useUnload from "context/use-unload.js";
import { useSnackbar } from "context/snackbar-context.js";
import style from "assets/jss/material-dashboard-pro-react/views/weeklyInput.js";
import usePrompt from "components/Prompt/usePrompt";

// firestore
import app from "firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// custom functions
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import WeeklyInputDialog from "components/WeeklyInputDialog/WeeklyInputDialog.js";
import { useAuth } from "context/auth-context.js";
import { InferProps } from "prop-types";
import { InputActionType, useInputContext } from "context/input-context";
import DayWizard from "components/DayWizard/DayWizard";
import {
  WeeklyComponentDayOption,
  WeeklyFireStoreState,
  inputListReducer,
  getTotalDays,
  setLoading,
  ComponentState,
} from "./SteppedComponentHelper";

// Analytics
import {
  fetchInputWeek,
  fetchAdminOptions,
  fetchPrevInputWeeks,
  fetchPastWeeklyInput,
  filterAssetUnavailable,
  resetOptionsDays,
} from "./weeklyHelpers";

import {
  sendStateController,
  getTableRows,
  fetchCompanyCache,
  InputFormPropType,
  InputFormDefaultProps,
} from "../commonHelpers";

import { createBadge } from "./weeklyTablesHelpers";

// core components
import WeeklyInputStep1 from "./steps/WeeklyInputStep1";
import WeeklyInputStep2 from "./steps/WeeklyInputStep2";
import WeeklyInputStep3 from "./steps/WeeklyInputStep3";

import { componentIds } from "../stackedInput/StackedInputHelper";
import {
  getInitWeek,
  SelectedWeek,
  WeekInfo,
} from "../weeklyElevateInput/WeeklyElevateInput";

const useStyles = makeStyles(style as any);

const getInitAction = (): ComponentState<WeeklyComponentDayOption> => ({
  options: [],
  selection: [],
  deleted: [],
  isUpdated: false,
  isLoading: false,
  isError: false,
});

const containsNullOrUndefined = (...args: any[]) => {
  return args.some((x) => x == null);
};

function WeeklyInput({ isStackedView }: InferProps<typeof InputFormPropType>) {
  const db = getFirestore(app);
  const auth = useAuth();
  const note = useSnackbar();
  const inputContext = useInputContext();
  const classes = useStyles();

  const [currentWeek, setCurrentWeek] = useState<WeekInfo>(getInitWeek());
  const [previousWeek, setPreviousWeek] = useState<WeekInfo>(getInitWeek());

  const [selectedWeek, setSelectedWeek] = useState<SelectedWeek>("CURRENT");

  // firestore reports
  const [previousReport, setPreviousReport] =
    useState<WeeklyFireStoreState | null>(null);
  const [currentReport, setCurrrentReport] =
    useState<WeeklyFireStoreState | null>(null);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [triggerAfterApprove, setTriggerAfterApprove] = useState(false);

  // Initial state for sourcing, building, admin, workload
  const [sourceCompany, dispatchSourceCompany] = useReducer(
    inputListReducer,
    getInitAction()
  );

  const [buildCompany, dispatchBuildCompany] = useReducer(
    inputListReducer,
    getInitAction()
  );

  const [adminCompany, dispatchAdminCompany] = useReducer(
    inputListReducer,
    getInitAction()
  );

  const adminRows = getTableRows(adminCompany, dispatchAdminCompany);
  const buildRows = getTableRows(buildCompany, dispatchBuildCompany);
  const sourceRows = getTableRows(sourceCompany, dispatchSourceCompany);

  const getTotalRow = () => {
    const totalDays = getTotalDays([
      ...adminCompany.selection,
      ...buildCompany.selection,
      ...sourceCompany.selection,
    ]);

    if (totalDays > 0) {
      return [
        {
          total: true,
          colspan: "1",
          amount: createBadge("total-1", totalDays),
        },
      ];
    }
    return [];
  };

  const isApprove = selectedWeek === "PREVIOUS";

  // Workload tab state
  const [workload, setWorkload] = useState("");

  function setFetchedOptions(
    buildOptions: WeeklyComponentDayOption[],
    sourceOptions: WeeklyComponentDayOption[],
    adminOptions: WeeklyComponentDayOption[]
  ) {
    dispatchAdminCompany({
      type: "SET_FETCHED_OPTIONS",
      payload: { fetchedOptions: adminOptions },
    });

    dispatchBuildCompany({
      type: "SET_FETCHED_OPTIONS",
      payload: { fetchedOptions: buildOptions },
    });

    dispatchSourceCompany({
      type: "SET_FETCHED_OPTIONS",
      payload: { fetchedOptions: sourceOptions },
    });
  }
  async function setPastInput(
    firestoreState: WeeklyFireStoreState,
    isCurrentWeek: boolean,
    isPreFilled: boolean
  ) {
    if (
      containsNullOrUndefined(
        firestoreState.sourcing,
        firestoreState.building,
        firestoreState.admin,
        firestoreState.workload
      )
    ) {
      note.sendNotification(
        "Your Firestore Cache is corrupt. Please contact an admin and report this incident.",
        "warning"
      );
    } else {
      if (isCurrentWeek && !isPreFilled) {
        note.sendNotification(
          `You already submitted a report for this week. To edit your report, re-submit it.`,
          "info"
        );
      } else if (isCurrentWeek && isPreFilled) {
        note.sendNotification(
          `Your report is prefilled with your previous inputs!`,
          "info"
        );
      }

      dispatchSourceCompany({
        type: "SET_FETCHED_SELECTION",
        payload: { fetchedSelection: firestoreState.sourcing },
      });
      dispatchBuildCompany({
        type: "SET_FETCHED_SELECTION",
        payload: { fetchedSelection: firestoreState.building },
      });
      dispatchAdminCompany({
        type: "SET_FETCHED_SELECTION",
        payload: { fetchedSelection: firestoreState.admin },
      });
      setWorkload(firestoreState.workload);
    }
  }

  useUnload((e: any) => {
    if (
      sourceCompany.isUpdated ||
      buildCompany.isUpdated ||
      adminCompany.isUpdated
    ) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  useEffect(() => {
    // This function ensures the order in which resources are fetched.
    async function fetchChain() {
      const dispatchFunctions = [
        dispatchSourceCompany,
        dispatchAdminCompany,
        dispatchBuildCompany,
      ];
      setLoading(dispatchFunctions, true);

      const currentWeekInfo = await fetchInputWeek(auth.user);
      setCurrentWeek(currentWeekInfo);

      const pastWeekInfos = await fetchPrevInputWeeks(auth.user, 1);
      const prevWeekInfo = pastWeekInfos[0];
      setPreviousWeek(prevWeekInfo);

      const [
        currentWeekInput,
        previousWeekInput,
        sourceOptions,
        buildOptions,
        adminOptions,
      ] = await Promise.all([
        fetchPastWeeklyInput(
          db,
          `${auth.user.email}_${currentWeekInfo.formattedWeek}`
        ),
        fetchPastWeeklyInput(
          db,
          `${auth.user.email}_${prevWeekInfo.formattedWeek}`
        ),
        fetchCompanyCache(db, "ip_sourcing_choices"),
        fetchCompanyCache(db, "ip_building_choices"),
        fetchAdminOptions(),
      ]);

      setFetchedOptions(buildOptions, sourceOptions, adminOptions);
      setCurrrentReport(currentWeekInput);
      setPreviousReport(previousWeekInput);
      if (
        previousWeekInput !== null &&
        previousWeekInput.isApproved === false
      ) {
        await setPastInput(previousWeekInput, false, false);
        setSelectedWeek("PREVIOUS");
      } else {
        if (currentWeekInput != null) {
          await setPastInput(currentWeekInput, true, false);
        } else if (previousWeekInput !== null) {
          await setPastInput(
            resetOptionsDays(
              filterAssetUnavailable(
                previousWeekInput,
                sourceOptions,
                buildOptions,
                adminOptions
              )
            ),
            true,
            true
          );
        }
        setSelectedWeek("CURRENT");
      }
      setLoading(dispatchFunctions, false);
    }

    fetchChain();
  }, [auth.user, db, triggerAfterApprove]);

  usePrompt(
    "You are about to leave the Weekly Report without having saved your inputs. Unsaved changes will be discarded. Do you want to continue?",
    sourceCompany.isUpdated || buildCompany.isUpdated || adminCompany.isUpdated
  );
  const weekFor = isApprove
    ? previousWeek.formattedWeek
    : currentWeek.formattedWeek;

  const instruction = () => {
    if (isApprove) {
      return (
        <h4>
          Please approve your report from previous{" "}
          <span className={classes.forwardHighlight}>
            week {previousWeek.inputWeek}
          </span>
          , {previousWeek.inputYear}.<br />
          <small className={classes.forwardHighlight}>
            {previousWeek.startWeek} - {previousWeek.endWeek}
          </small>
        </h4>
      );
    }
    return (
      <h4>
        What will you work on in{" "}
        <span className={classes.forwardHighlight}>
          week {currentWeek.inputWeek}
        </span>
        , {currentWeek.inputYear}? <br />
        <small className={classes.forwardHighlight}>
          {currentWeek.startWeek} - {currentWeek.endWeek}
        </small>
      </h4>
    );
  };
  // This action is safe to be called on every submit,
  // A successfully submitted form is always considered complete in the current
  // logic. Reducer makes sure incompletecount >= 0
  const callbackOnSuccess = () => {
    const analytics = getAnalytics(app);
    logEvent(analytics, "IP Weekly Report Submitted");
    if (!isApprove) {
      inputContext.dispatch({
        type: InputActionType.DECREMENT_INCOMPLETE_COUNT,
        payload: componentIds.WEEKLY,
      });
    } else {
      setTriggerAfterApprove(true);
      setSelectedWeek("CURRENT");
    }
  };

  const tableRows = [
    ...sourceRows,
    ...buildRows,
    ...adminRows,
    ...getTotalRow(),
  ];

  const setOpenDialogCallback = () => {
    if (!isApprove) {
      setOpenDialog(true);
    }
  };

  return (
    <>
      {!isStackedView && (
        <WeeklyInputDialog
          setOpenDialog={setOpenDialog}
          isOpenDialog={isOpenDialog}
          type="success"
          message="Report successfully submitted."
        />
      )}
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={10}>
          <DayWizard
            initialStep={isApprove ? 2 : 0}
            finishButtonText={isApprove ? "Approve" : "Finish"}
            isPast={isApprove}
            pastReportText={"You are approving last week's report."}
            isSubmitLoading={isSubmitLoading}
            timeInstruction={instruction()}
            steps={[
              {
                stepName: "Source & Invest",
                component: (
                  <WeeklyInputStep1
                    sourceCompany={sourceCompany}
                    dispatchSourceCompany={dispatchSourceCompany}
                    tableRows={tableRows}
                  />
                ),
              },

              {
                stepName: "Build & Exit",
                component: (
                  <WeeklyInputStep2
                    tableRows={tableRows}
                    buildCompany={buildCompany}
                    dispatchBuildCompany={dispatchBuildCompany}
                  />
                ),
              },

              {
                stepName: "Admin & Workload",
                component: (
                  <WeeklyInputStep3
                    adminCompany={adminCompany}
                    dispatchAdminCompany={dispatchAdminCompany}
                    workload={workload}
                    tableRows={tableRows}
                    setWorkload={setWorkload}
                  />
                ),
              },
            ]}
            title="Weekly Report"
            finishButtonClick={() => {
              if (workload === "") {
                note.sendNotification(
                  "Input for workload is empty. Please fill it before submitting the report. Thank you!",
                  "error"
                );
                return;
              }
              const totalDays = getTotalDays([
                ...adminCompany.selection,
                ...buildCompany.selection,
                ...sourceCompany.selection,
              ]);
              if (totalDays === 0) {
                note.sendNotification(
                  "The report you try to submit is empty. Please add items to your report first, and then submit it again.",
                  "error"
                );
                return;
              }

              // This deletes 0 day inputs from firestore
              const firestoreState = {
                admin: adminCompany.selection.filter((c) => c.days !== 0),
                building: buildCompany.selection.filter((c) => c.days !== 0),
                sourcing: sourceCompany.selection.filter((c) => c.days !== 0),
                workload,
                isApproved: isApprove,
              };

              sendStateController(
                "week_for",
                weekFor,
                auth.user,
                db,
                "weekly_input",
                firestoreState,
                setIsSubmitLoading,
                note,
                setOpenDialogCallback,
                [
                  dispatchAdminCompany,
                  dispatchBuildCompany,
                  dispatchSourceCompany,
                ],
                callbackOnSuccess,
                `${auth.user.email}_${weekFor}`
              );
            }}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
WeeklyInput.prototype = InputFormPropType;
WeeklyInput.defaultProps = InputFormDefaultProps;
export default WeeklyInput;
