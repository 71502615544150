import React, { useState, useContext, createContext } from "react";
import Alert from "components/CustomAlert/CustomAlert.js";
import { Snackbar } from "@mui/material";

const snackbarContext = createContext();

function SnackbarProvider(props) {
  const snackbar = useProvideSnackbar();
  return <snackbarContext.Provider value={snackbar} {...props} />;
}

const useSnackbar = () => {
  return useContext(snackbarContext);
};

function useProvideSnackbar() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const sendNotification = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const initializeNotification = () => {
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {/* <SnackBar's child needs to be an element that can hold a ref, e.g. a div. */}
        <div>
          <Alert
            onClose={handleClose}
            color={severity}
            variant="standard"
            severity={severity}
          >
            {message}
          </Alert>
        </div>
      </Snackbar>
    );
  };
  return {
    sendNotification,
    initializeNotification,
  };
}

export { SnackbarProvider, useSnackbar, snackbarContext };
